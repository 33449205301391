(function () {
  'use strict';
  angular
  .module('app')
  .component('lobbyHome', {
    templateUrl: 'app/components/lobby_home/lobby_home.html',
    controller: LobbyHomeController
  });

  LobbyHomeController.$inject = ['$timeout', '$window', 'user','toastr','cashierService','$uibModal','$rootScope','miscelaneos','$stateParams','$state'];

  function LobbyHomeController($timeout, $window, $user, toastr, cashierService, $uibModal, $rootScope, $miscelaneos, $stateParams, $state) {
    var vm = this;

    vm.$onInit = function() {
      vm.currentUser = $user.getCurrentUser();
    }
  }

})();
