angular
  .module('app')
  .component('modalConfirmLoteries', {
    templateUrl: 'app/components/loteries/modalConfirm.html',
    controller: ModalConfirmController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

ModalConfirmController.$inject = ['miscelaneos'];

function ModalConfirmController($miscelaneos) {
  var vm = this;
  vm.cancel = cancel;
  vm.deletePlay = deletePlay;
  vm.confirm = confirm;

  vm.$onInit = function () {
    vm.plays = vm.resolve.plays;
    vm.playsDenied = vm.resolve.playsDenied;
    vm.cajero_web = vm.resolve.cajero_Web;
    console.log(vm.cajero_Web)
    vm.draws = _.reduce($miscelaneos.getAndUncompressDraws(), function (memo, data) {
      memo = memo.concat(data.sorteos);
      return memo;
    }, []);


    vm.listNegados = _.reduce(vm.playsDenied.negados, function(memo, data){
      var jugada = _.findWhere(vm.plays, {i: data.i});
      memo.push({
        i: data.i,
        numero: jugada.n,
        signo: jugada.s,
        monto: jugada.m,
        disponible: data.m,
        sorteo: _.findWhere(vm.draws, { id: parseInt(jugada.c) }).nombre
      })
      return memo
    },[])

    // _.forEach(vm.plays, function (draw) {
    //   draw.tickets = [];
    // })
    // _.forEach(vm.playsDenied.negados, function (ticketNegado) {
    //   _.forEach(vm.plays, function (draw) {
    //     draw.sorteo_pretty = _.findWhere(vm.draws, { id: parseInt(draw.c) });
    //     var findTicket = _.findWhere(draw.j, { i: ticketNegado.i });
    //     if (findTicket) {
    //       var ticket = ticketNegado;
    //       ticket.prettyTicket = findTicket;
    //       draw.tickets.push(ticket);
    //     }
    //   });
    // });
  }

  function confirm() {
    vm.close({ $value: angular.copy(vm.playsDenied) });
  }

  function cancel() {
    vm.dismiss({ $value: angular.copy(vm.playsDenied) });
  }

  function deletePlay(index) {
    vm.plays.splice(index, 1);
  }

}
