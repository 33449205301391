(function () {
  'use strict';
  angular
  .module('app')
  .component('detailTransaction', {
    templateUrl: 'app/components/lobby-user/detail_transaction.html',
    controller: detailTransaction,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  detailTransaction.$inject = ['$sce'];

  function detailTransaction($sce) {
    var vm = this;

    vm.$onInit = function() {
      vm.detail_transaction = vm.resolve.detail_transaction;
      vm.detail_transaction.pretty_ticket = $sce.trustAsHtml(angular.copy(vm.detail_transaction.detalle.replace(/\n|\r|\\n/g, '<br>')));
    }

  }

})();
