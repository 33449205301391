(function () {
  'use strict';
  angular
  .module('app')
  .component('noWinning', {
    templateUrl: 'app/components/noWinning/noWinning.html',
    controller: NoWinningController
  });

  NoWinningController.$inject = ['$state', 'noWinningService', 'user','$stateParams', 'cashierService','$rootScope','$uibModal'];

  function NoWinningController($state, $noWinningService, $user, $stateParams, cashierService, $rootScope, $uibModal) {
    var vm = this;

    vm.$onInit = function() {
      vm.loading = true;
      vm.currentUser = $user.getCurrentUser();
      vm.loteria_id = null;
      vm.ticket = {
        telefono: '',
        cedula: 'V-'
      }
      if(!vm.currentUser.super_juegos.non_winnings){
        swal('info', 'No hay rifas activas en este momento','info')
        setTimeout(function(){
          $state.go('lobby');
        },2000);
      }else{
        vm.currentNoWinningRaffle = vm.currentUser.super_juegos.non_winnings;
        console.log(vm.currentNoWinningRaffle)
      }
      // if ($stateParams.cashier_id == '') {
      //   getAllCashiers(vm.loteria_id)
      // } else {
      //   if (vm.currentUser.cajero.currency == 'Bs' && vm.currentUser.pay_pago_movil) {
      //     showModalPagoMovil();
      //   }
      //   vm.loading = false;
      // }
      
    }

    function getAllCashiers(loteria_id) {
      cashierService.getAllCashiers(loteria_id)
        .then(function (res) {
          vm.allCashiers = res;
          showWallets(res);
        })
    }
  
    function showWallets(allCashiers) {
      var modal = $uibModal.open({
        animation: true,
        component: 'modalAllCashiers',
        windowClass: 'modalAllCashiers',
        backdrop: 'static',
        keyboard: false,
        size: 'xs',
        resolve: {
          allCashiers: function () {
            return allCashiers;
          }
        }
      })
      modal.result.then(function (res) {
        var oldCashier = vm.currentUser.cajero;
        var newFavorite = _.findWhere(res, { checked: true });
        if (newFavorite) {
          vm.loadingCajeroWeb = true;
          vm.currentUser.cajero = newFavorite;
          $user.setCurrentUser(vm.currentUser);
          $rootScope.$broadcast('updatedUser', vm.currentUser);
          $state.go("noWinning", { "cashier_id": vm.currentUser.cajero.id });
        }
      })
    }
  
    function showModalPagoMovil() {
      var modal = $uibModal.open({
        animation: true,
        component: 'modalPagoMovil',
        windowClass: 'modalPagoMovil',
        backdrop: 'static',
        keyboard: false,
        size: 'xs',
      })
      modal.result.then(function (res) {
        vm.pagoMovil = res;
      })
    }


    vm.save = function(){
      var data = {
        non_winning_raffle_id: vm.currentNoWinningRaffle.id,
        ticket_id: vm.ticket.ticket_id,
        serial: vm.ticket.serial,
        cedula: vm.ticket.cedula,
        telefono: vm.ticket.telefono
      }
      $noWinningService.addticket(data)
      .then(function(res){
        swal('Info',res.message,'success')
        $state.go('lobby');
      }, function(err){
        swal('Info',res.data.message,'info')
      })
    }
  }

})();
