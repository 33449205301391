(function () {
  'use strict';
  angular
    .module('app')
    .component('tickets', {
      templateUrl: 'app/components/tickets/tickets.html',
      controller: TicketsController,
    });

  TicketsController.$inject = ['$window', 'user', '$sce', 'agentsPanelService', '$uibModal', '$state', '$timeout','$rootScope'];

  function TicketsController($window, $user, $sce, $agentsPanelService, $uibModal, $state, $timeout, $rootScope) {
    var vm = this;
    var vm = this;
    vm.tipoJuegos = [{value: null, key: ''}];
    vm.tipoJuegoSelected = vm.tipoJuegos[0];
    vm.statuses = [{value: null, key: ''},{ value: 'vendido', key: 'Vendido'}, { value: 'r', key: 'Reversado'}, { value: 'g', key: 'Ganador'}, { value: 'd', key: 'Devuelto'}];
    vm.statusSelected = vm.statuses[0];

    vm.$onInit = function () {
      vm.loading = true;
      vm.currentUser = $user.getCurrentUser();
      $agentsPanelService.getTipoJuego()
      .then(function(res){
        vm.tipoJuegos = res;
      })
      $agentsPanelService.getTicketsPrintView({view: 'all_tickets'})
      .then(function(res){
        vm.loading = false;
        vm.tickets = [];
        $timeout(function(){
          vm.tickets = res;
        },0)
      })
    }

    vm.showTicket = function(tracking){
      vm.ticket = $sce.trustAsHtml(angular.copy(tracking.ticket.replace(/\n|\r/g, '<br>')));
      vm.ticket_ws = angular.copy(tracking.ticket.replace(/\\n/g, '<br>'));
      vm.ticket_print = angular.copy(tracking.ticket.replace(/\n|\r/g, '\n'));
      var show_actions = false;
      $timeout(function () {
        modalTicket(vm.ticket, vm.ticket_print, vm.ticket_ws, tracking)
      }, 0)
    }

    vm.consult = function(){
      vm.loading = true;
      var params = {
        date_from: moment(vm.dateFrom).format('YYYY-MM-DD'),
        date_to: moment(vm.dateTo).format('YYYY-MM-DD'),
        view: 'all_tickets',
        status: vm.statusSelected.value,
        tipo_juego: vm.tipoJuegoSelected.value
      }
      $agentsPanelService.getTicketsPrintView(params)
      .then(function(res){
        vm.loading = false;
        vm.tickets = [];
        $timeout(function(){
          vm.tickets = res;
        },0)
      })
    }

    vm.showDetalleTicket = function(tracking){
      var modal = $uibModal.open({
        animation: true,
        component: 'modalDetalleTicket',
        backdrop: 'static',
        windowClass: 'modalTicket',
        size: 'lg',
        resolve: {
          tracking: function () {
            return tracking;
          }
        },
      })
  
      modal.result.then(function (res) {
        // vm.$onInit();
      }, function(err) {
        // vm.$onInit();
      })
    }

    function modalTicket(ticket, ticket_print, ticket_ws, tracking) {
      var modal = $uibModal.open({
        animation: true,
        component: 'gModalTicket',
        backdrop: 'static',
        windowClass: 'modalTicket',
        size: 'xs',
        resolve: {
          ticket: function () {
            return ticket;
          },
          ticket_print: function () {
            return ticket_print;
          },
          ticket_ws: function () {
            return ticket_ws;
          },
          tracking_id: function() {
            return tracking.id
          },
          template: function(){
            return 3;
          }
        },
      })
  
      modal.result.then(function (res) {
        // vm.$onInit();
      }, function(err) {
        // vm.$onInit();
      })
    }

    vm.today = function() {
      vm.dateFrom = new Date();
      vm.dateTo = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.dt = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      // maxDate: new Date(2020, 5, 22),
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false,
      showButtonBar: false,
    };

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };
    vm.toggleMin();

    vm.open1 = function() {
      vm.popup1.opened = true;
    };

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.dateFrom = new Date(year, month, day);
      vm.dateTo = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup1 = {
      opened: false
    };

    vm.popup2 = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

    $rootScope.$on('updateListTickets', function(ev, data){
      vm.$onInit();
    })
  }

})();
