angular
.module('app')
.component('modalDetalleTicket', {
  templateUrl: 'app/components/tickets/ticket_detalle.html',
  controller: ModalDetallTicket,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

ModalDetallTicket.$inject = ['$window','user', '$timeout','hotkeys','agentsPanelService','$state'];

function ModalDetallTicket($window, $user, $timeout, hotkeys, $agentsPanelService, $state){
  var vm = this;
  vm.cancel = cancel;

  vm.$onInit = function(){
    vm.currentUser = $user.getCurrentUser();
    vm.currentState = $state.current.name;
    vm.tracking = vm.resolve.tracking;
    console.log(vm.tracking,'vm.tracking')
    
  }

  function cancel(){
    vm.dismiss({$value: 'cancel'});
  }
}
