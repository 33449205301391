(function (){
  'use strict'
  angular
  .module('app')
  .factory('miscelaneos', miscelaneos);

  miscelaneos.$inject = ['api','$http','user']

  function miscelaneos(api, $http, $user){
    return {
      getCurrency: getCurrency,
      getBanksOperadora: getBanksOperadora,
      addBankStructure: addBankStructure,
      updateBankStructure: updateBankStructure,
      getBanksStructure: getBanksStructure,
      getAndUncompressDraws: getAndUncompressDraws,
      getSigns: getSigns,
      getSystemVersions: getSystemVersions,
      getBancos: getBancos,
      updateProfile: updateProfile,
      getNotifications: getNotifications,
      getNewNotifications: getNewNotifications,
      toggleViewNotification: toggleViewNotification,
      getDefaultSolicitudOperacion: getDefaultSolicitudOperacion,
      getSettingCajeroWeb: getSettingCajeroWeb,
      getImagesToBonus: getImagesToBonus,
      getNumbersPermuted: getNumbersPermuted,
      getSerieNumbers: getSerieNumbers,
      getCorridaNumbers: getCorridaNumbers,
      unsubscribe: unsubscribe,
      getCurrentPromo: getCurrentPromo,
      getProductIdByGame: getProductIdByGame,
      draws: draws,
      clearRifaMax: clearRifaMax
    }

    function clearRifaMax(data){
      $http.post('https://api.rifa-max.com/x100/tickets/clear', data, {headers: { 'Authorization': 'Bearer rm_live_ed8c46ee-06fb-4d12-b194-387ddb3578d0' }})
      .then(function(res){
        return res.data;
      })
    }

    function toggleViewNotification(data){
      return $http.post(api.getApiSPJ('notifications/toggle_view_notification'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getNewNotifications(){
      return $http.get(api.getApiSPJ('notifications/new_notifications'))
      .then(function(res){
        return res.data;
      })
    }

    function getNotifications(){
      return $http.get(api.getApiSPJ('notifications'))
      .then(function(res){
        return res.data;
      })
    }

    function updateProfile(data){
      return $http.post(api.getApiSPJ('estructura/update_profile'), data)
      .then(function(res){
        return res.data;
      })
    }


    function getBanksOperadora(){
      return $http.get(api.getApiSPJ('bancos_operadora'))
      .then(function(res){
        return res.data;
      })
    }

    function getBanksStructure(){
      return $http.get(api.getApiSPJ('bancos_estructura'))
      .then(function(res){
        return res.data;
      })
    }

    function addBankStructure(data){
      return $http.post(api.getApiSPJ('bancos_estructura'), data)
      .then(function(res){
        return res.data;
      })
    }
    function updateBankStructure(id, data){
      return $http.put(api.getApiSPJ('bancos_estructura/'+id), data)
      .then(function(res){
        return res.data;
      })
    }

    function getCurrency(){
      return {symbol: 'Bs.S ', decimal: 2, separatorMiles: '.', separatorDecimal: ','}
    }

    function getSystemVersions(){
      return $http.get(api.getApiSPJ('miscelaneos/get_system_versions'))
      .then(function(res){
        return res.data;
      })
    }
    function getCurrentPromo(){
      return $http.get(api.getApiSPJ('miscelaneos/get_current_promo'))
      .then(function(res){
        return res.data;
      })
    }

    function getBancos(){
      return $http.get(api.getApiSPJ('miscelaneos/bancos'))
      .then(function(res){
        return res.data;
      })
    }

    function getDefaultSolicitudOperacion(){
      return $http.get(api.getApiSPJ('miscelaneos/default_solicitud_operacion'))
      .then(function(res){
        return res.data;
      })
    }

    function getSettingCajeroWeb(){
      return $http.get(api.getApiSPJ('miscelaneos/get_setting_cajero_web '))
      .then(function(res){
        return res.data;
      })
    }

    function getAndUncompressDraws(){
      var b64Data     = $user.getCurrentUser().super_juegos.lote;
      var strData     = atob(b64Data);
      var charData    = strData.split('').map(function(x){return x.charCodeAt(0);});
      var binData     = new Uint8Array(charData);
      var data        = pako.inflate(binData);
      var strData     = String.fromCharCode.apply(null, new Uint16Array(data));
      return JSON.parse(strData);
    }

    function getNumbersPermuted(data){
      return $http.post(api.getApiSPJ('miscelaneos/get_numbers_permuted'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getSerieNumbers(data){
      return $http.post(api.getApiSPJ('miscelaneos/get_serie_numbers'), data)
      .then(function(res){
        return res.data;
      })
    }
    function getCorridaNumbers(data){
      return $http.post(api.getApiSPJ('miscelaneos/get_corrida_numbers'), data)
      .then(function(res){
        return res.data;
      })
    }

    function unsubscribe(id){
      return $http.get(api.getApiSPJ('estructura/unsubscribe?id='+id))
      .then(function(res){
        return res.data;
      })
    }



    function getSigns() {
            return [
        {
          name: "Aries",
          image: "/images/signos/aries.png",
          image_active: "/images/signos/aries-selected.png",
          checked: false,
          available: true,
          code: 1
        },
        {
          name: "Tauro",
          image: "/images/signos/tauro.png",
          image_active: "/images/signos/tauro-selected.png",
          checked: false,
          available: true,
          code: 2
        },
        {
          name: "Geminis",
          image: "/images/signos/geminis.png",
          image_active: "/images/signos/geminis-selected.png",
          checked: false,
          available: true,
          code: 3
        },
        {
          name: "Cancer",
          image: "/images/signos/cancer.png",
          image_active: "/images/signos/cancer-selected.png",
          checked: false,
          available: true,
          code: 4
        },
        {
          name: "Leo",
          image: "/images/signos/leo.png",
          image_active: "/images/signos/leo-selected.png",
          checked: false,
          available: true,
          code: 5
        },
        {
          name: "Virgo",
          image: "/images/signos/virgo.png",
          image_active: "/images/signos/virgo-selected.png",
          checked: false,
          available: true,
          code: 6
        },
        {
          name: "Libra",
          image: "/images/signos/libra.png",
          image_active: "/images/signos/libra-selected.png",
          checked: false,
          available: true,
          code: 7
        },
        {
          name: "Escorpio",
          image: "/images/signos/escorpio.png",
          image_active: "/images/signos/escorpio-selected.png",
          checked: false,
          available: true,
          code: 8
        },
        {
          name: "Sagitario",
          image: "/images/signos/sagitario.png",
          image_active: "/images/signos/sagitario-selected.png",
          checked: false,
          available: true,
          code: 9
        },
        {
          name: "Capricornio",
          image: "/images/signos/capricornio.png",
          image_active: "/images/signos/capricornio-selected.png",
          checked: false,
          available: true,
          code: 10
        },
        {
          name: "Acuario",
          image: "/images/signos/acuario.png",
          image_active: "/images/signos/acuario-selected.png",
          checked: false,
          available: true,
          code: 11
        },
        {
          name: "Piscis",
          image: "/images/signos/piscis.png",
          image_active: "/images/signos/piscis-selected.png",
          checked: false,
          available: true,
          code: 12
        }
      ]
    }

    function getImagesToBonus(){
      return [
        {
          loteria_id: 4,
          image: '/images/games/tq-lobby-1598898341.png'
        },{
          loteria_id: 3,
          image: '/images/games/rc-lobby-1598898341.png'
        },{
          loteria_id: 5,
          image: '/images/games/triple-zulia-1598898341.png'
        },{
          loteria_id: 9,
          image: '/images/games/triple-zamorano-1598898341.png'
        },{
          loteria_id: 15,
          image: '/images/games/jungla-lobby-03102059.png'
        },{
          loteria_id: 6,
          image: '/images/games/triple-tachira.png'
        },{
          loteria_id: 13,
          image: '/images/games/triple-caliente-1598898341.png'
        },{
          loteria_id: 17,
          image: '/images/games/la-granjita.png'
        },{
          loteria_id: 8,
          image: '/images/games/triple-caracas.png'
        },{
          loteria_id: 7,
          image: '/images/games/chance-en-linea.png'
        },{
          loteria_id: 18,
          image: '/images/games/lotto-activo-1.png'
        },{
          loteria_id: 19,
          image: '/images/games/lotto-activo-1.png'
        }
      ]
    }
  }

  function draws(){
    return [
      { horac: '08:55', code_sorteo: '08', nombre: 'Sorteo 09:00 AM', checked: false },
      { horac: '09:55', code_sorteo: '09', nombre: 'Sorteo 10:00 AM', checked: false },
      { horac: '10:55', code_sorteo: '10', nombre: 'Sorteo 11:00 AM', checked: false },
      { horac: '11:55', code_sorteo: '11', nombre: 'Sorteo 12:00  M', checked: false },
      { horac: '12:55', code_sorteo: '12', nombre: 'Sorteo 01:00 PM', checked: false },
      { horac: '13:55', code_sorteo: '13', nombre: 'Sorteo 02:00 PM', checked: false },
      { horac: '14:55', code_sorteo: '14', nombre: 'Sorteo 03:00 PM', checked: false },
      { horac: '15:55', code_sorteo: '15', nombre: 'Sorteo 04:00 PM', checked: false },
      { horac: '16:55', code_sorteo: '16', nombre: 'Sorteo 05:00 PM', checked: false },
      { horac: '17:55', code_sorteo: '17', nombre: 'Sorteo 06:00 PM', checked: false },
      { horac: '18:55', code_sorteo: '18', nombre: 'Sorteo 07:00 PM', checked: false }
    ]
  }

  function getProductIdByGame(game){
    return {
      'TuQuintico': 1,
      'Jungla': 2,
      'Rifcar': 3,
      'Triples': 5,
      'LaGranjita': 6,
      'LottoActivo': 8,
      'Granjazo':22
    }[game];

  }

})();
