angular
.module('app')
.component('modalAllCashiers', {
  templateUrl: 'app/components/modals/modalSelectCashiers.html',
  controller: ModalSelectCashiersController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

ModalSelectCashiersController.$inject = ['$state','user'];

function ModalSelectCashiersController($state, $user){
  var vm = this;
  vm.cancel = cancel;
  vm.setCashier = setCashier;
  vm.currentUser = $user.getCurrentUser();

  vm.$onInit = function(){
    vm.currentState = $state.current.name;
    vm.allCashiers = _.map(vm.resolve.allCashiers, function(data){
      data.checked = false;
      return data;
    });
  }

  function setCashier(cashier){
    _.forEach(vm.allCashiers, function(data){
      data.checked = false;
    })
    cashier.checked = true;

    vm.close({$value: vm.allCashiers});
  }

  function cancel(){
    vm.dismiss({$value: 'cancel'});
  }

  vm.gotToCashier = function(){
    vm.dismiss({$value: 'cancel'})
    $state.go("lobbyUser", {"cashier_id": null});
    // vm.close({$value: 'cashier'});
  }

  vm.gotToLobby = function(){
    vm.dismiss({$value: 'cancel'})
    $state.go("lobby");
  }
  

}
