(function () {
  'use strict';
  angular
  .module('app')
  .component('payTicket', {
    templateUrl: 'app/components/payTicket/payTicket.html',
    controller: PayTicketController,
  });

  PayTicketController.$inject = ['user','agentsPanelService','miscelaneos','$sce'];

  function PayTicketController($user, $agentsPanelService, $miscelaneos, $sce) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();
    vm.loading = false;

    vm.$onInit = function(){ }

    vm.consult = function(){
      vm.loading = true;
      var data = {
        date: moment(vm.date).format('YYYY-MM-DD'),
        ticketid: vm.ticketid,
        serial_seguridad: vm.serial_seguridad
      }
      $agentsPanelService.getTicketPremiados(data)
      .then(function(res){
        vm.premio = res.ticket_premiado;
        console.log(vm.premio)
        vm.premio.parse_ticket = $sce.trustAsHtml(angular.copy(vm.premio.ticket_string.replace(/\n|\r/g, '<br>')));
        vm.total = _.reduce(vm.premio.detalle_premios, function(memo, data){
          memo.total_jugado += parseFloat(data.montoj);
          memo.total_ganado += parseFloat(data.montop);
          memo.total_pagado += Number(data.monto_pagado);
          return memo;
        },{total_jugado: 0, total_pagado: 0, total_ganado: 0});
        vm.loading = false;
      }, function(err){
        vm.loading = false;
        swal('Info',err.data.msg, 'warning')
      })
    }

    vm.payAward = function(){
      vm.loading = true;
      var data = {
        ticketp_id: vm.premio.id
      }
      $agentsPanelService.pagoPremio(data)
      .then(function(res){
        vm.loading = false;
        swal('Info',res.msg, 'success')
      }, function(err){
        vm.loading = false;
        swal('Info',err.data.msg, 'warning')
      })
    }


    vm.today = function() {
      vm.date = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.dt = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      // maxDate: new Date(2020, 5, 22),
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false,
      showButtonBar: false,
    };

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };
    vm.toggleMin();

    vm.open1 = function() {
      vm.popup1.opened = true;
    };

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.dt = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup1 = {
      opened: false
    };

    vm.popup2 = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

    
  }

})();
