angular
  .module('app')
  .component('home', {
    templateUrl: 'app/components/home/home.html',
    controller: HomeController
  });

  HomeController.$inject = ['moment','$timeout', '$uibModal','miscelaneos'];

function HomeController(moment, $timeout, $uibModal, $miscelaneos) {
  var vm = this;
  vm.active = 0;
  vm.date = moment();
  vm.resultsZoo = [];
  vm.loading1 = true;
  vm.loading2 = false;
  vm.loading3 = false;
  vm.loading4 = true;
  vm.myInterval = 4000;
  vm.resultQuintico = [];
  vm.noWrapSlides = false;
  vm.humanizeNumbers = ['Cero','Uno','Dos','Tres','Cuatro','Cinco','Seis','Siete','Ocho','Nueve'];
  vm.slides = [
    {
      image: '/images/home/banners_plataforma_oficial_031120211610.png',
      link: 'home'
    },{
      image: '/images/home/banners-plataforma-031120211611.png',
      link: 'home'
    },{
      image: '/images/home/banners-tuquintico-1599284306.jpg',
      link: 'http://www.quintico.com'
    },{
      image: '/images/home/banners-loto-1599284306.jpg',
      link: '/registro/agentes'
    },{
      image: '/images/home/banner-rifcar-kia-rio031120211612.png',
      link: 'home'
    }
  ];

  vm.$onInit = function(){

    // $miscelaneos.getCurrentPromo()
    // .then(function(res){
    //   if(res){
    //     var modal = $uibModal.open({
    //       animation: true,
    //       component: 'modalPromo',
    //       windowClass: 'modalPopup',
    //       size: 'md',
    //       resolve: {
    //         promo: function(){
    //           return res;
    //         }
    //       }
    //     });
    //   }
    // })
  }

}
