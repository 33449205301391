(function () {
  'use strict';
  angular
  .module('app')
  .component('auth', {
    // templateUrl: 'app/components/auth/auth.html',
    template: '<div ng-include="$ctrl.urlTemplate"></div>',
    controller: AuthController
  });

  AuthController.$inject = ['authService', 'user', '$window','$rootScope','$stateParams','availableProducts'];

  function AuthController($authService, $user, $window, $rootScope, $stateParams,availableProducts) {
    var vm = this;
    vm.send = false;
    vm.doLogin = doLogin;
    vm.user = {email: '', password: ''};

    vm.$onInit = function(){
      // if($stateParams.cdapuestas){
        vm.urlTemplate = 'app/components/auth/auth-cdapuestas.html';
      // }else{
      //   vm.urlTemplate = 'app/components/auth/auth.html';
      // }
    }

    function doLogin() {

      vm.send = true;
      $authService.doLogin(vm.user)
      .then(function (res) {
        vm.send = false;
        vm.user = res.estructura;
        vm.user.usuario = vm.user.usuarios[0];
        availableProducts.setCurrentProducts(res.products);
        $user.setCurrentUser(vm.user);
        $rootScope.$broadcast('updatedUser', vm.user);
        setTimeout(function(){
          if(vm.user.tipo_estructura == 'agente') {
            $window.location = '/panel-de-agentes/area-de-usuario';
          } else {
            $window.location = '/panel-de-agentes/lobby';
          }
        },0)
        
      }, function (err) {
        vm.send = false;
        $window.swal('Error!',err.data.message,'warning');
      });
    }
  }

})();
