
// src/app/directives/showPassword.js
(function () {
  'use strict';
  angular
    .module('app')
    .directive('isPassword', function() {
      return {
        restrict : 'A', 
        scope: {
          isPassword: '=isPassword'
        },
        link: function (scope, element, attrs) {
          scope.$watch('isPassword', function(a, b){
            element.attr('type', a ? 'password' : 'text')
          })
        }
      }
    })})();