(function () {
  'use strict';
  angular
    .module('app')
    .factory('animalitoService', animalitoService);

  animalitoService.$inject = ['$http', 'api'];

  function animalitoService($http, api) {

    function sale(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/nueva_venta_v2'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function confirmSaleZoo(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/confirmar_venta'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function reverTicket(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/reversar_venta'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function getAnimalsV1(directory) {
      return [
        {
          image: '/images/' + directory + '/01.png',
          image_active: '/images/' + directory + '/01-active.png',
          number: "01",
          selected: false,
          name: "Carnero",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/02.png',
          image_active: '/images/' + directory + '/02-active.png',
          number: "02",
          selected: false,
          name: "Toro",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/03.png',
          image_active: '/images/' + directory + '/03-active.png',
          number: "03",
          selected: false,
          name: "Ciempiés",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/04.png',
          image_active: '/images/' + directory + '/04-active.png',
          number: "04",
          selected: false,
          name: "Alacrán",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/05.png',
          image_active: '/images/' + directory + '/05-active.png',
          number: "05",
          selected: false,
          name: "León",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/06.png',
          image_active: '/images/' + directory + '/06-active.png',
          number: "06",
          selected: false,
          name: "Rana",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/07.png',
          image_active: '/images/' + directory + '/07-active.png',
          number: "07",
          selected: false,
          name: "Perico",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/08.png',
          image_active: '/images/' + directory + '/08-active.png',
          number: "08",
          selected: false,
          name: "Ratón",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/09.png',
          image_active: '/images/' + directory + '/09-active.png',
          number: "09",
          selected: false,
          name: "Águila",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/10.png',
          image_active: '/images/' + directory + '/10-active.png',
          number: "10",
          selected: false,
          name: "Tigre",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/11.png',
          image_active: '/images/' + directory + '/11-active.png',
          number: "11",
          selected: false,
          name: "Gato",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/12.png',
          image_active: '/images/' + directory + '/12-active.png',
          number: "12",
          selected: false,
          name: "Caballo",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/13.png',
          image_active: '/images/' + directory + '/13-active.png',
          number: "13",
          selected: false,
          name: "Mono",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/14.png',
          image_active: '/images/' + directory + '/14-active.png',
          number: "14",
          selected: false,
          name: "Paloma",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/15.png',
          image_active: '/images/' + directory + '/15-active.png',
          number: "15",
          selected: false,
          name: "Zorro",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/16.png',
          image_active: '/images/' + directory + '/16-active.png',
          number: "16",
          selected: false,
          name: "Oso",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/17.png',
          image_active: '/images/' + directory + '/17-active.png',
          number: "17",
          selected: false,
          name: "Pavo",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/18.png',
          image_active: '/images/' + directory + '/18-active.png',
          number: "18",
          selected: false,
          name: "Burro",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/19.png',
          image_active: '/images/' + directory + '/19-active.png',
          number: "19",
          selected: false,
          name: "Chivo",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/20.png',
          image_active: '/images/' + directory + '/20-active.png',
          number: "20",
          selected: false,
          name: "Cochino",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/21.png',
          image_active: '/images/' + directory + '/21-active.png',
          number: "21",
          selected: false,
          name: "Gallo",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/22.png',
          image_active: '/images/' + directory + '/22-active.png',
          number: "22",
          selected: false,
          name: "Camello",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/23.png',
          image_active: '/images/' + directory + '/23-active.png',
          number: "23",
          selected: false,
          name: "Cebra",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/24.png',
          image_active: '/images/' + directory + '/24-active.png',
          number: "24",
          selected: false,
          name: "Iguana",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/25.png',
          image_active: '/images/' + directory + '/25-active.png',
          number: "25",
          selected: false,
          name: "Gallina",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/26.png',
          image_active: '/images/' + directory + '/26-active.png',
          number: "26",
          selected: false,
          name: "Vaca",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/27.png',
          image_active: '/images/' + directory + '/27-active.png',
          number: "27",
          selected: false,
          name: "Perro",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/28.png',
          image_active: '/images/' + directory + '/28-active.png',
          number: "28",
          selected: false,
          name: "Zamuro",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/29.png',
          image_active: '/images/' + directory + '/29-active.png',
          number: "29",
          selected: false,
          name: "Elefante",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/30.png',
          image_active: '/images/' + directory + '/30-active.png',
          number: "30",
          selected: false,
          name: "Caimán",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/31.png',
          image_active: '/images/' + directory + '/31-active.png',
          number: "31",
          selected: false,
          name: "Lapa",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/32.png',
          image_active: '/images/' + directory + '/32-active.png',
          number: "32",
          selected: false,
          name: "Ardilla",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/33.png',
          image_active: '/images/' + directory + '/33-active.png',
          number: "33",
          selected: false,
          name: "Pescado",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/34.png',
          image_active: '/images/' + directory + '/34-active.png',
          number: "34",
          selected: false,
          name: "Venado",
          color: 'red'
        },
        {
          image: '/images/' + directory + '/35.png',
          image_active: '/images/' + directory + '/35-active.png',
          number: "35",
          selected: false,
          name: "Jirafa",
          color: 'black'
        },
        {
          image: '/images/' + directory + '/36.png',
          image_active: '/images/' + directory + '/36-active.png',
          number: "36",
          selected: false,
          name: "Culebra",
          color: 'red'
        }, {
          image: '/images/' + directory + '/37.png',
          image_active: '/images/' + directory + '/37-active.png',
          number: "37",
          selected: false,
          name: "Mariposa",
          color: 'green'
        },
        {
          image: '/images/' + directory + '/38.png',
          image_active: '/images/' + directory + '/38-active.png',
          number: "38",
          selected: false,
          name: "Conejo",
          color: 'green'
        },

      ]
    }

    function getAnimalsV2(directory) {
      return [
        {
          image: '/images/' + directory + '/01.png',
          image_active: '/images/' + directory + '/01-active.png',
          number: '01',
          selected: false,
          name: 'Carnero',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/02.png',
          image_active: '/images/' + directory + '/02-active.png',
          number: '02',
          selected: false,
          name: 'Toro',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/03.png',
          image_active: '/images/' + directory + '/03-active.png',
          number: '03',
          selected: false,
          name: 'Ciempies',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/04.png',
          image_active: '/images/' + directory + '/04-active.png',
          number: '04',
          selected: false,
          name: 'Alacran',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/05.png',
          image_active: '/images/' + directory + '/05-active.png',
          number: '05',
          selected: false,
          name: 'Leon',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/06.png',
          image_active: '/images/' + directory + '/06-active.png',
          number: '06',
          selected: false,
          name: 'Rana',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/07.png',
          image_active: '/images/' + directory + '/07-active.png',
          number: '07',
          selected: false,
          name: 'Perico',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/08.png',
          image_active: '/images/' + directory + '/08-active.png',
          number: '08',
          selected: false,
          name: 'Raton',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/09.png',
          image_active: '/images/' + directory + '/09-active.png',
          number: '09',
          selected: false,
          name: 'Aguila',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/10.png',
          image_active: '/images/' + directory + '/10-active.png',
          number: '10',
          selected: false,
          name: 'Tigre',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/11.png',
          image_active: '/images/' + directory + '/11-active.png',
          number: '11',
          selected: false,
          name: 'Gato',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/12.png',
          image_active: '/images/' + directory + '/12-active.png',
          number: '12',
          selected: false,
          name: 'Caballo',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/13.png',
          image_active: '/images/' + directory + '/13-active.png',
          number: '13',
          selected: false,
          name: 'Mono',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/14.png',
          image_active: '/images/' + directory + '/14-active.png',
          number: '14',
          selected: false,
          name: 'Paloma',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/15.png',
          image_active: '/images/' + directory + '/15-active.png',
          number: '15',
          selected: false,
          name: 'Zorro',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/16.png',
          image_active: '/images/' + directory + '/16-active.png',
          number: '16',
          selected: false,
          name: 'Oso',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/17.png',
          image_active: '/images/' + directory + '/17-active.png',
          number: '17',
          selected: false,
          name: 'Pavo',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/18.png',
          image_active: '/images/' + directory + '/18-active.png',
          number: '18',
          selected: false,
          name: 'Burro',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/19.png',
          image_active: '/images/' + directory + '/19-active.png',
          number: '19',
          selected: false,
          name: 'Chivo',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/20.png',
          image_active: '/images/' + directory + '/20-active.png',
          number: '20',
          selected: false,
          name: 'Cochino',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/21.png',
          image_active: '/images/' + directory + '/21-active.png',
          number: '21',
          selected: false,
          name: 'Gallo',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/22.png',
          image_active: '/images/' + directory + '/22-active.png',
          number: '22',
          selected: false,
          name: 'Camello',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/23.png',
          image_active: '/images/' + directory + '/23-active.png',
          number: '23',
          selected: false,
          name: 'Zebra',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/24.png',
          image_active: '/images/' + directory + '/24-active.png',
          number: '24',
          selected: false,
          name: 'Iguana',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/25.png',
          image_active: '/images/' + directory + '/25-active.png',
          number: '25',
          selected: false,
          name: 'Gallina',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/26.png',
          image_active: '/images/' + directory + '/26-active.png',
          number: '26',
          selected: false,
          name: 'Vaca',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/27.png',
          image_active: '/images/' + directory + '/27-active.png',
          number: '27',
          selected: false,
          name: 'Perro',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/28.png',
          image_active: '/images/' + directory + '/28-active.png',
          number: '28',
          selected: false,
          name: 'Zamuro',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/29.png',
          image_active: '/images/' + directory + '/29-active.png',
          number: '29',
          selected: false,
          name: 'Elefante',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/30.png',
          image_active: '/images/' + directory + '/30-active.png',
          number: '30',
          selected: false,
          name: 'Caiman',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/31.png',
          image_active: '/images/' + directory + '/31-active.png',
          number: '31',
          selected: false,
          name: 'Lapa',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/32.png',
          image_active: '/images/' + directory + '/32-active.png',
          number: '32',
          selected: false,
          name: 'Ardilla',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/33.png',
          image_active: '/images/' + directory + '/33-active.png',
          number: '33',
          selected: false,
          name: 'pescado',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/34.png',
          image_active: '/images/' + directory + '/34-active.png',
          number: '34',
          selected: false,
          name: 'Venado',
          color: 'red'
        },
        {
          image: '/images/' + directory + '/35.png',
          image_active: '/images/' + directory + '/35-active.png',
          number: '35',
          selected: false,
          name: 'Jirafa',
          color: 'black'
        },
        {
          image: '/images/' + directory + '/36.png',
          image_active: '/images/' + directory + '/36-active.png',
          number: '36',
          selected: false,
          name: 'Culebra',
          color: 'red'
        }, {
          image: '/images/' + directory + '/0.png',
          image_active: '/images/' + directory + '/0-active.png',
          number: '0',
          selected: false,
          name: 'Delfin',
          color: 'green'
        },
        {
          image: '/images/' + directory + '/00.png',
          image_active: '/images/' + directory + '/00-active.png',
          number: '00',
          selected: false,
          name: 'Ballena',
          color: 'green'
        },

      ]
    }

    function getAnimalsV3(directory) {
      return [{
        name: "Carnero",
        number: '01',
        image: '/images/' + directory + '/01.png',
        image_active: '/images/' + directory + '/01-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Toro",
        number: "02",
        image: '/images/' + directory + '/02.png',
        image_active: '/images/' + directory + '/02-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Ciempiés",
        number: "03",
        image: '/images/' + directory + '/03.png',
        image_active: '/images/' + directory + '/03-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Alacrán",
        number: "04",
        image: '/images/' + directory + '/04.png',
        image_active: '/images/' + directory + '/04-active.png',
        selected: false,
        color: ''
      },
      {
        name: "León",
        number: "05",
        image: '/images/' + directory + '/05.png',
        image_active: '/images/' + directory + '/05-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Rana",
        number: "06",
        image: '/images/' + directory + '/06.png',
        image_active: '/images/' + directory + '/06-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Perico",
        number: "07",
        image: '/images/' + directory + '/07.png',
        image_active: '/images/' + directory + '/07-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Ratón",
        number: "08",
        image: '/images/' + directory + '/08.png',
        image_active: '/images/' + directory + '/08-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Águila",
        number: "09",
        image: '/images/' + directory + '/09.png',
        image_active: '/images/' + directory + '/09-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Tigre",
        number: "10",
        image: '/images/' + directory + '/10.png',
        image_active: '/images/' + directory + '/10-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Gato",
        number: "11",
        image: '/images/' + directory + '/11.png',
        image_active: '/images/' + directory + '/11-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Caballo",
        number: "12",
        image: '/images/' + directory + '/12.png',
        image_active: '/images/' + directory + '/12-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Mono",
        number: "13",
        image: '/images/' + directory + '/13.png',
        image_active: '/images/' + directory + '/13-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Paloma",
        number: "14",
        image: '/images/' + directory + '/14.png',
        image_active: '/images/' + directory + '/14-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Zorro",
        number: "15",
        image: '/images/' + directory + '/15.png',
        image_active: '/images/' + directory + '/15-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Oso",
        number: "16",
        image: '/images/' + directory + '/16.png',
        image_active: '/images/' + directory + '/16-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Pavo",
        number: "17",
        image: '/images/' + directory + '/17.png',
        image_active: '/images/' + directory + '/17-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Burro",
        number: "18",
        image: '/images/' + directory + '/18.png',
        image_active: '/images/' + directory + '/18-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Chivo",
        number: "19",
        image: '/images/' + directory + '/19.png',
        image_active: '/images/' + directory + '/19-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Cochino",
        number: "20",
        image: '/images/' + directory + '/20.png',
        image_active: '/images/' + directory + '/20-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Gallo",
        number: "21",
        image: '/images/' + directory + '/21.png',
        image_active: '/images/' + directory + '/21-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Camello",
        number: "22",
        image: '/images/' + directory + '/22.png',
        image_active: '/images/' + directory + '/22-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Cebra",
        number: "23",
        image: '/images/' + directory + '/23.png',
        image_active: '/images/' + directory + '/23-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Iguana",
        number: "24",
        image: '/images/' + directory + '/24.png',
        image_active: '/images/' + directory + '/24-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Gallina",
        number: "25",
        image: '/images/' + directory + '/25.png',
        image_active: '/images/' + directory + '/25-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Vaca",
        number: "26",
        image: '/images/' + directory + '/26.png',
        image_active: '/images/' + directory + '/26-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Perro",
        number: "27",
        image: '/images/' + directory + '/27.png',
        image_active: '/images/' + directory + '/27-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Zamuro",
        number: "28",
        image: '/images/' + directory + '/28.png',
        image_active: '/images/' + directory + '/28-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Elefante",
        number: "29",
        image: '/images/' + directory + '/29.png',
        image_active: '/images/' + directory + '/29-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Caimán",
        number: "30",
        image: '/images/' + directory + '/30.png',
        image_active: '/images/' + directory + '/30-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Lapa",
        number: "31",
        image: '/images/' + directory + '/31.png',
        image_active: '/images/' + directory + '/31-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Ardilla",
        number: "32",
        image: '/images/' + directory + '/32.png',
        image_active: '/images/' + directory + '/32-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Pescado",
        number: "33",
        image: '/images/' + directory + '/33.png',
        image_active: '/images/' + directory + '/33-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Venado",
        number: "34",
        image: '/images/' + directory + '/34.png',
        image_active: '/images/' + directory + '/34-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Jirafa",
        number: "35",
        image: '/images/' + directory + '/35.png',
        image_active: '/images/' + directory + '/35-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Culebra",
        number: "36",
        image: '/images/' + directory + '/36.png',
        image_active: '/images/' + directory + '/36-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Mariposa",
        number: "37",
        image: '/images/' + directory + '/37.png',
        image_active: '/images/' + directory + '/37-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Conejo",
        number: "38",
        image: '/images/' + directory + '/38.png',
        image_active: '/images/' + directory + '/38-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Rinoceronte",
        number: "39",
        image: '/images/' + directory + '/39.png',
        image_active: '/images/' + directory + '/39-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Hipopótamo",
        number: "40",
        image: '/images/' + directory + '/40.png',
        image_active: '/images/' + directory + '/40-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Búfalo",
        number: "41",
        image: '/images/' + directory + '/41.png',
        image_active: '/images/' + directory + '/41-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Murciélago",
        number: "42",
        image: '/images/' + directory + '/42.png',
        image_active: '/images/' + directory + '/42-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Koala",
        number: "43",
        image: '/images/' + directory + '/43.png',
        image_active: '/images/' + directory + '/43-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Puma",
        number: "44",
        image: '/images/' + directory + '/44.png',
        image_active: '/images/' + directory + '/44-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Canguro",
        number: "45",
        image: '/images/' + directory + '/45.png',
        image_active: '/images/' + directory + '/45-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Lobo",
        number: "46",
        image: '/images/' + directory + '/46.png',
        image_active: '/images/' + directory + '/46-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Tortuga",
        number: "47",
        image: '/images/' + directory + '/47.png',
        image_active: '/images/' + directory + '/47-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Morsa",
        number: "48",
        image: '/images/' + directory + '/48.png',
        image_active: '/images/' + directory + '/48-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Hiena",
        number: "49",
        image: '/images/' + directory + '/49.png',
        image_active: '/images/' + directory + '/49-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Comadreja",
        number: "50",
        image: '/images/' + directory + '/50.png',
        image_active: '/images/' + directory + '/50-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Drogón",
        number: "51",
        image: '/images/' + directory + '/51.png',
        image_active: '/images/' + directory + '/51-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Chacal",
        number: "52",
        image: '/images/' + directory + '/52.png',
        image_active: '/images/' + directory + '/52-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Jaguar",
        number: "53",
        image: '/images/' + directory + '/53.png',
        image_active: '/images/' + directory + '/53-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Camaleón",
        number: "54",
        image: '/images/' + directory + '/54.png',
        image_active: '/images/' + directory + '/54-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Foca",
        number: "55",
        image: '/images/' + directory + '/55.png',
        image_active: '/images/' + directory + '/55-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Armiño",
        number: "56",
        image: '/images/' + directory + '/56.png',
        image_active: '/images/' + directory + '/56-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Jabalí",
        number: "57",
        image: '/images/' + directory + '/57.png',
        image_active: '/images/' + directory + '/57-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Castor",
        number: "58",
        image: '/images/' + directory + '/58.png',
        image_active: '/images/' + directory + '/58-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Guacamayo",
        number: "59",
        image: '/images/' + directory + '/59.png',
        image_active: '/images/' + directory + '/59-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Tiburón",
        number: "60",
        image: '/images/' + directory + '/60.png',
        image_active: '/images/' + directory + '/60-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Turpial",
        number: "61",
        image: '/images/' + directory + '/61.png',
        image_active: '/images/' + directory + '/61-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Tucán",
        number: "62",
        image: '/images/' + directory + '/62.png',
        image_active: '/images/' + directory + '/62-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Cachicamo",
        number: "63",
        image: '/images/' + directory + '/63.png',
        image_active: '/images/' + directory + '/63-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Hormiga",
        number: "64",
        image: '/images/' + directory + '/64.png',
        image_active: '/images/' + directory + '/64-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Gorila",
        number: "65",
        image: '/images/' + directory + '/65.png',
        image_active: '/images/' + directory + '/65-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Bisonte",
        number: "66",
        image: '/images/' + directory + '/66.png',
        image_active: '/images/' + directory + '/66-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Flamingo",
        number: "67",
        image: '/images/' + directory + '/67.png',
        image_active: '/images/' + directory + '/67-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Araña",
        number: "68",
        image: '/images/' + directory + '/68.png',
        image_active: '/images/' + directory + '/68-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Pavo Real",
        number: "69",
        image: '/images/' + directory + '/69.png',
        image_active: '/images/' + directory + '/69-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Cangrejo",
        number: "70",
        image: '/images/' + directory + '/70.png',
        image_active: '/images/' + directory + '/70-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Rabipelao",
        number: "71",
        image: '/images/' + directory + '/71.png',
        image_active: '/images/' + directory + '/71-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Pelicano",
        number: "72",
        image: '/images/' + directory + '/72.png',
        image_active: '/images/' + directory + '/72-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Colibrí",
        number: "73",
        image: '/images/' + directory + '/73.png',
        image_active: '/images/' + directory + '/73-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Escarabajo",
        number: "74",
        image: '/images/' + directory + '/74.png',
        image_active: '/images/' + directory + '/74-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Codorniz",
        number: "75",
        image: '/images/' + directory + '/75.png',
        image_active: '/images/' + directory + '/75-active.png',
        selected: false,
        color: ''
      },
      {
        name: "Mantis",
        number: "76",
        image: '/images/' + directory + '/76.png',
        image_active: '/images/' + directory + '/76-active.png',
        selected: false,
        color: ''
      }]
    }

    function getAnimalsV4(directory) {
      return [
        {
          name: 'Delfin',
          number: "0",
          image: '/images/' + directory + "/0.png",
          image_active: '/images/' + directory + "/0-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Ballena',
          number: "00",
          image: '/images/' + directory + "/00.png",
          image_active: '/images/' + directory + "/00-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Carnero',
          number: "01",
          image: '/images/' + directory + "/01.png",
          image_active: '/images/' + directory + "/01-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Toro',
          number: "02",
          image: '/images/' + directory + "/02.png",
          image_active: '/images/' + directory + "/02-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Ciempies',
          number: "03",
          image: '/images/' + directory + "/03.png",
          image_active: '/images/' + directory + "/03-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Alacran',
          number: "04",
          image: '/images/' + directory + "/04.png",
          image_active: '/images/' + directory + "/04-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Leon',
          number: "05",
          image: '/images/' + directory + "/05.png",
          image_active: '/images/' + directory + "/05-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Rana',
          number: "06",
          image: '/images/' + directory + "/06.png",
          image_active: '/images/' + directory + "/06-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Perico',
          number: "07",
          image: '/images/' + directory + "/07.png",
          image_active: '/images/' + directory + "/07-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Raton',
          number: "08",
          image: '/images/' + directory + "/08.png",
          image_active: '/images/' + directory + "/08-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Aguila',
          number: "09",
          image: '/images/' + directory + "/09.png",
          image_active: '/images/' + directory + "/09-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Tigre',
          number: "10",
          image: '/images/' + directory + "/10.png",
          image_active: '/images/' + directory + "/10-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Gato',
          number: "11",
          image: '/images/' + directory + "/11.png",
          image_active: '/images/' + directory + "/11-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Caballo',
          number: "12",
          image: '/images/' + directory + "/12.png",
          image_active: '/images/' + directory + "/12-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Mono',
          number: "13",
          image: '/images/' + directory + "/13.png",
          image_active: '/images/' + directory + "/13-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Paloma',
          number: "14",
          image: '/images/' + directory + "/14.png",
          image_active: '/images/' + directory + "/14-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Zorro1',
          number: "15",
          image: '/images/' + directory + "/15.png",
          image_active: '/images/' + directory + "/15-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Oso',
          number: "16",
          image: '/images/' + directory + "/16.png",
          image_active: '/images/' + directory + "/16-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Pavo',
          number: "17",
          image: '/images/' + directory + "/17.png",
          image_active: '/images/' + directory + "/17-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Burro',
          number: "18",
          image: '/images/' + directory + "/18.png",
          image_active: '/images/' + directory + "/18-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Chivo',
          number: "19",
          image: '/images/' + directory + "/19.png",
          image_active: '/images/' + directory + "/19-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Cochino',
          number: "20",
          image: '/images/' + directory + "/20.png",
          image_active: '/images/' + directory + "/20-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Gallo',
          number: "21",
          image: '/images/' + directory + "/21.png",
          image_active: '/images/' + directory + "/21-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Camello',
          number: "22",
          image: '/images/' + directory + "/22.png",
          image_active: '/images/' + directory + "/22-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Cebra',
          number: "23",
          image: '/images/' + directory + "/23.png",
          image_active: '/images/' + directory + "/23-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Iguana',
          number: "24",
          image: '/images/' + directory + "/24.png",
          image_active: '/images/' + directory + "/24-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Gallina',
          number: "25",
          image: '/images/' + directory + "/25.png",
          image_active: '/images/' + directory + "/25-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Vaca',
          number: "26",
          image: '/images/' + directory + "/26.png",
          image_active: '/images/' + directory + "/26-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Perro',
          number: "27",
          image: '/images/' + directory + "/27.png",
          image_active: '/images/' + directory + "/27-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Zamuro',
          number: "28",
          image: '/images/' + directory + "/28.png",
          image_active: '/images/' + directory + "/28-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Elefante',
          number: "29",
          image: '/images/' + directory + "/29.png",
          image_active: '/images/' + directory + "/29-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Caiman',
          number: "30",
          image: '/images/' + directory + "/30.png",
          image_active: '/images/' + directory + "/30-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Lapa',
          number: "31",
          image: '/images/' + directory + "/31.png",
          image_active: '/images/' + directory + "/31-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Ardilla',
          number: "32",
          image: '/images/' + directory + "/32.png",
          image_active: '/images/' + directory + "/32-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Pescado',
          number: "33",
          image: '/images/' + directory + "/33.png",
          image_active: '/images/' + directory + "/33-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Venado',
          number: "34",
          image: '/images/' + directory + "/34.png",
          image_active: '/images/' + directory + "/34-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Jirafa',
          number: "35",
          image: '/images/' + directory + "/35.png",
          image_active: '/images/' + directory + "/35-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Conejo',
          number: "36",
          image: '/images/' + directory + "/36.png",
          image_active: '/images/' + directory + "/36-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Colibri',
          number: "37",
          image: '/images/' + directory + "/37.png",
          image_active: '/images/' + directory + "/37-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Flamenco',
          number: "38",
          image: '/images/' + directory + "/38.png",
          image_active: '/images/' + directory + "/38-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Gavilan',
          number: "39",
          image: '/images/' + directory + "/39.png",
          image_active: '/images/' + directory + "/39-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Halcon',
          number: "40",
          image: '/images/' + directory + "/40.png",
          image_active: '/images/' + directory + "/40-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Búfalo',
          number: "41",
          image: '/images/' + directory + "/41.png",
          image_active: '/images/' + directory + "/41-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Pelicano',
          number: "42",
          image: '/images/' + directory + "/42.png",
          image_active: '/images/' + directory + "/42-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Garza',
          number: "43",
          image: '/images/' + directory + "/43.png",
          image_active: '/images/' + directory + "/43-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Canguro',
          number: "44",
          image: '/images/' + directory + "/44.png",
          image_active: '/images/' + directory + "/44-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Tortuga',
          number: "45",
          image: '/images/' + directory + "/45.png",
          image_active: '/images/' + directory + "/45-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Corocoro',
          number: "46",
          image: '/images/' + directory + "/46.png",
          image_active: '/images/' + directory + "/46-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Avestruz',
          number: "47",
          image: '/images/' + directory + "/47.png",
          image_active: '/images/' + directory + "/47-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Coyote',
          number: "48",
          image: '/images/' + directory + "/48.png",
          image_active: '/images/' + directory + "/48-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Pinguino',
          number: "49",
          image: '/images/' + directory + "/49.png",
          image_active: '/images/' + directory + "/49-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Cisne',
          number: "50",
          image: '/images/' + directory + "/50.png",
          image_active: '/images/' + directory + "/50-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Buho',
          number: "51",
          image: '/images/' + directory + "/51.png",
          image_active: '/images/' + directory + "/51-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Lobo',
          number: "52",
          image: '/images/' + directory + "/52.png",
          image_active: '/images/' + directory + "/52-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Ganzo',
          number: "53",
          image: '/images/' + directory + "/53.png",
          image_active: '/images/' + directory + "/53-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Tiburon',
          number: "54",
          image: '/images/' + directory + "/54.png",
          image_active: '/images/' + directory + "/54-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Gacela',
          number: "55",
          image: '/images/' + directory + "/55.png",
          image_active: '/images/' + directory + "/55-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Hiena',
          number: "56",
          image: '/images/' + directory + "/56.png",
          image_active: '/images/' + directory + "/56-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Buitre',
          number: "57",
          image: '/images/' + directory + "/57.png",
          image_active: '/images/' + directory + "/57-active.png",
          selected: false,
          color: '',
        },
        {
          name: 'Bisonte',
          number: "58",
          image: '/images/' + directory + "/58.png",
          image_active: '/images/' + directory + "/58-active.png",
          selected: false,
          color: '',
        }
      ]
    }

    return {
      sale: sale,
      confirmSaleZoo: confirmSaleZoo,
      reverTicket: reverTicket,
      getAnimalsV2: getAnimalsV2,
      getAnimalsV1: getAnimalsV1,
      getAnimalsV3: getAnimalsV3,
      getAnimalsV4: getAnimalsV4
    };

  }
})();
