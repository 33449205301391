angular
  .module('app', [
    'ui.router',
    'ngAnimate',
    'ngSanitize',
    'ngResource',
    'ui.bootstrap',
    'vcRecaptcha',
    'toastr',
    'ui.select',
    'ngFileUpload',
    'angular-button-spinner',
    'ngActionCable',
    'ds.clock',
    'ngMask',
    'btford.socket-io',
    'ja.qr',
    'cfp.hotkeys',
    'timer'
  ]);
