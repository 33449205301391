(function () {
  'use strict';
  angular
  .module('app')
  .component('estaciones', {
    template: '<div ng-include="$ctrl.urlTemplate"></div>',
    controller: EstacionController,
  });

  EstacionController.$inject = ['$window', 'user','toastr','agentsPanelService','$rootScope','$stateParams','$state','cashierService'];

  function EstacionController($window, $user, toastr, $agentsPanelService, $rootScope, $stateParams, $state,cashierService) {
    var vm = this;
    vm.saveEstacion = saveEstacion;
    
    
    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
      vm.title = vm.currentUser.tipo_estructura == 'agencia' ? 'Estaciones' : 'Estaciones Auto Servicio'
      vm.tipo_estructuras = [{id: 'auto_servicio', nombre: 'Estación Auto Servicio'}, {id: 'estacion', nombre: 'Estacion'}]
      vm.tipoEstructuraSelected = vm.tipo_estructuras[1];  
      if($stateParams.action == 'create-estacion'){
        vm.urlTemplate = 'app/components/estaciones/crear_estacion.html';
        if($stateParams.estacion){
          $agentsPanelService.estaciones.get({id: parseInt($stateParams.estacion)}).$promise
          .then(function(res){
            vm.estacion = res;
            vm.estacion.username = res.usuarios[0].username;
          })
        }
      }else{
        vm.urlTemplate = 'app/components/estaciones/estaciones.html';
        $agentsPanelService.estaciones.query({tipo_estructura: vm.currentUser.tipo_estructura}).$promise.then(function(res){
          vm.estaciones = res;
          vm.loading = false;
        })
      }
      

      
    }

    function saveEstacion(){

      var data = {
        nombre: vm.estacion.nombre,
        tipo_estructura: vm.estacion.tipo_estructura,
        usuario: {
          username: vm.estacion.username,
          password: vm.estacion.password,
          activo: true
        },
        activo: true,
      }

      

      if(vm.estacion.id){
        data.id = vm.estacion.id;
        $agentsPanelService.estaciones.update({id: vm.estacion.id}, data, function(res){
          vm.estacion = undefined;
          toastr.success(res.message);
          $state.go('estaciones', {action: 'estaciones', estacion: null});
        });
      }else{
        $agentsPanelService.estaciones.save(data).$promise.then(function(res){
          vm.estacion = undefined;
          toastr.success(res.message);
          $state.go('estaciones', {action: 'estaciones', estacion: null});
        });
      }

      // if(vm.estacion.id){
      //   data.id = vm.estacion.id
      //   $agentsPanelService.updateEstacion(data)
      //   .then(function(res){
      //     vm.estacion = undefined;
      //     toastr.success(res.message);
      //     $state.go('agentspanel');
      //   })
      // }else{
      //   $agentsPanelService.createEstacion(data)
      //   .then(function(res){
      //     vm.estacion = undefined;
      //     toastr.success(res.message);
      //     $state.go('agentspanel');
      //   })
      // }

      
    }


  }

})();
