(function () {
  'use strict';
  angular
  .module('app')
  .component('pointSales', {
    template: '<div ng-include="$ctrl.urlTemplate"></div>',
    controller: PointSalesController,
  });

  PointSalesController.$inject = ['$window', 'user','toastr','agentsPanelService','$rootScope','$stateParams','$state','cashierService'];

  function PointSalesController($window, $user, toastr, $agentsPanelService, $rootScope, $stateParams, $state,cashierService) {
    var vm = this;
    vm.savePointSales = savePointSales;
    vm.viewPromotionalCode = false;
    vm.showPrintCode = showPrintCode;
    vm.print = print;
    vm.modifyPointSale = modifyPointSale;
    vm.point_sale = {es_credito: false};
    vm.steps = {
      step1: true,
      step2: false,
      step3: false,
    }
    
    vm.$onInit = function(){
      console.log($stateParams.action)
      console.log($stateParams.action == 'crear-agencia')
      vm.currentUser = $user.getCurrentUser();
      cashierService.getLimitCredit()
      .then(function(res){
        vm.limitCredit = res;

        cashierService.getAllMonedas()
        .then(function(res){
          vm.allCashiers = _.map(res, function(data){
            data.limit_general = _.findWhere(vm.currentUser.conf_cajero_web_creditos, {moneda_id: data.id}).amount;
            data.limit_used = _.findWhere(vm.limitCredit, {moneda_id: data.id}).used_by_structure;
            return data
          });
          
          if($stateParams.action == 'crear-agencia'){
            vm.urlTemplate = 'app/components/point-sales/crear-point-sales.html';
            if(!isNaN(parseInt($stateParams.point_sales))){
              $agentsPanelService.getMyPointSale($stateParams.point_sales)
              .then(function(res){
                vm.point_sale = res;
                vm.point_sale.username = res.username;
                vm.loading = false;
                vm.allCashiers = vm.point_sale.cashiers;
                vm.allCashiers = _.map(vm.allCashiers, function(data){
                  data.limit_general = _.findWhere(vm.currentUser.conf_cajero_web_creditos, {moneda_id: data.moneda_id}).amount;
                  data.limit_used = _.findWhere(vm.limitCredit, {moneda_id: data.moneda_id}).used_by_structure;
                  data.credito = data.referencia_credito;
                  return data
                });
                $agentsPanelService.getStructurePercentsBonus($stateParams.point_sales)
                .then(function(res){
                  vm.percentsBonus = _.map(res, function(data){
                    data.referencial = parseInt(data.comision);
                    data.id = data.id
                    return data;
                  })
                })
              })
            }else{
              $agentsPanelService.getMyPercentsBonus()
              .then(function(res){
                vm.percentsBonus = _.map(res, function(data){
                  data.referencial = parseInt(data.comision);
                  return data;
                })
              })
            }
          }else{
            vm.urlTemplate = 'app/components/point-sales/list-point-sales.html';
          }
        })
      })
      
      

      $agentsPanelService.getMyPointSales()
      .then(function(res){
        vm.point_sales = res;
        vm.loading = false;
      })
    }

    function savePointSales(){
      if(!vm.point_sale.id){
        _.forEach(vm.percentsBonus, function(data){
          if(parseInt(data.referencial) == parseInt(data.comision)){
            toastr.error('El bono para '+data.producto+' no puede ser igual al monto'+data.comision);
          }
        })
      }
      var configuracion_estructura = _.reduce(vm.percentsBonus, function(memo, data){
        memo.push({
          comision: data.referencial,
          loteria_id: data.loteria_id,
          participacion: data.participacion,
          id: vm.point_sale.id ? data.id : null
        })
        return memo;
      },[])
      var data = {
        nombre: vm.point_sale.nombre,
        representante: vm.point_sale.representante,
        telefono: vm.point_sale.telefono,
        direccion:vm.point_sale.direccion,
        correo: vm.point_sale.username,
        rif: vm.point_sale.rif,
        activo: true,
        username: vm.point_sale.username,
        password: vm.point_sale.password,
        password_confirmation:vm.point_sale.password_confirmation,
        configuracion_estructura_attributes: configuracion_estructura,
        cashiers: _.reduce(vm.allCashiers, function(memo, data){
          memo.push({
            moneda_id: data.id,
            currency: data.currency,
            es_credito: data.es_credito,
            referencia_credito: data.es_credito ? data.credito : null,
            efectivo: data.efectivo ? data.efectivo : null,
          })
          return memo;
        },[])
      }

      if(vm.point_sale.id){
        data.id = vm.point_sale.id
        $agentsPanelService.updatePointSale(data)
        .then(function(res){
          vm.point_sale = undefined;
          toastr.success(res.message);
          $state.go('agentspanel');
        })
      }else{
        $agentsPanelService.createPointSale(data)
        .then(function(res){
          vm.point_sale = undefined;
          toastr.success(res.message);
          $state.go('agentspanel');
        })
      }

      
    }

    function showPrintCode(data){
      vm.viewPromotionalCode = true;
      vm.currentPointSale = data;
      vm.currentPointSale.promotional_code_url = 'http://www.centrodeapuestas.com/register/'+data.promotional_code
    }
    function modifyPointSale(data){
      vm.currentPointSale = data;
      // vm.viewPromotionalCode = true;
      // vm.currentPointSale.promotional_code_url = 'http://www.centrodeapuestas.com/register/'+data.promotional_code
    }

    function print(){
      $window.print();
    }

    vm.nextStep = function(){
      if(vm.steps.step1){
        if(vm.point_sale.id){
          vm.steps.step1 = false;
          vm.steps.step3 = true;
        }else{
          vm.steps.step1 = false;
          vm.steps.step2 = true;
        }
      }else if(vm.steps.step2){
        vm.steps.step2 = false;
        vm.steps.step3 = true;
      }
    }

    vm.stepBack = function(){
      if(vm.steps.step2){
        vm.steps.step2 = false;
        vm.steps.step1 = true;
      }else if(vm.steps.step3){
        if(vm.point_sale.id){
          vm.steps.step3 = false;
          vm.steps.step1 = true;
        }else{
          vm.steps.step3 = false;
          vm.steps.step2 = true;
        }
      }
    }



  }

})();
