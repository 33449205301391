angular
.module('app')
.component('breadCrumb', {
  template: '<div ng-include="$ctrl.urlTemplate"></div>',
  controller: breadCrumb,
  bindings: {
    color: '<',
  }
});

breadCrumb.$inject = ['$state','user','moment', '$rootScope','cashierService', 'availableProducts'];

function breadCrumb($state, $user, moment, $rootScope, $cashierService, $availableProducts) {
  var vm = this;
  vm.currentState = null;
  vm.date = moment().format('DD-MM-YYYY');

  vm.$onInit = function(){
    vm.productsAvailables = $availableProducts.getCurrentProducts();

    if(window.mobileAndTabletcheck()){
      vm.urlTemplate = 'app/components/bread-crumbs/bread-crumb-mobile.html';
    }else{
      vm.urlTemplate = 'app/components/bread-crumbs/bread-crumb.html';
    }
    vm.currentUser = $user.getCurrentUser();
    vm.currentState = $state.current.name;
    if(vm.currentUser){
      $cashierService.getCoupon()
      .then(function(res){
        vm.coupon = res;
        if(vm.coupon != null){
          vm.coupon.pretty_expire = moment.unix(res.expire).format('DD/MM/YYYY');
        }
      })
    }
  }

  $rootScope.$on('updateCajeroUser', function(evt, data){
    delete vm.currentUser.cajero
    vm.currentUser.cajero = data;
    $user.setCurrentUser(vm.currentUser);
  })

  $rootScope.$on('updateCupon', function(evt, data){
    vm.coupon = data;
    vm.coupon.disponible = data.monto_cupon - data.monto_usado;
  })

  $rootScope.$on('updateState', function(evt, data){
    console.log(data, 'data')
    vm.currentState = data;
  })




}
