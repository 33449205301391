(function () {
  'use strict';
  angular
  .module('app')
  .component('deposit', {
    templateUrl: 'app/components/deposit/deposit.html',
    controller: DepositController
  });

  DepositController.$inject = ['$state', 'api', 'user','$stateParams','miscelaneos','Upload', 'cashierService','$rootScope','$uibModal','$window'];

  function DepositController($state, $api, $user, $stateParams, $miscelaneos, Upload, $cashierService, $rootScope, $uibModal,$window) {
    var vm = this;
    vm.save = save;
    vm.addBank = addBank;
    vm.showAnotherBank = false;

    vm.$onInit = function() {
      vm.currentUser = $user.getCurrentUser();
      vm.required = vm.currentUser.tipo_estructura == 'agencia' ? false : true;
      $cashierService.getAllCashiers()
      .then(function(res){
        vm.allCashiers = res;
        if($stateParams.moneda_id){
          vm.cashierSelected = _.findWhere(vm.allCashiers, { moneda_id: parseInt($stateParams.moneda_id) });
        }
        $miscelaneos.getDefaultSolicitudOperacion()
        .then(function(res){
          vm.allSettingsCashier = res;
          console.log(vm.allSettingsCashier)
          vm.default = _.findWhere(vm.allSettingsCashier, {currency: vm.cashierSelected.currency});
        })

        $miscelaneos.getBanksOperadora()
        .then(function(res){
          vm.allBancosOperadora = res;
          vm.bancos_operadora = _.reduce(vm.allBancosOperadora, function(memo, data){
            var exist = _.contains(data.currencies, parseInt(vm.cashierSelected.moneda_id));
            if(exist){
              data.active = false;
              data.otros = angular.fromJson(data.otros);
              memo.push(data);
            }
            return memo;
          },[]);
        })
      })
      $miscelaneos.getBanksStructure()
      .then(function(res){
        vm.banksStructure = res;
      })
      
    }

    function save(){
      vm.send = true;
      if(parseFloat(vm.deposit.monto) >= parseFloat(vm.default.monto_minimo_deposito)){
        if(vm.currentUser.tipo == 3){
          if(parseFloat(vm.deposit.monto) > parseFloat(vm.currentUser.cajero.monto_vip) && !vm.deposit.referencia){
            $window.swal('Info','Debe Ingresar la referencia','info');
            vm.send = false;
            return
          }
        }
        vm.deposit.status = 0;
        vm.deposit.tipo = 1;
        vm.deposit.estructura_envia = vm.currentUser.id;
        vm.deposit.moneda_id = vm.cashierSelected.moneda_id;
        vm.deposit.currency = vm.cashierSelected.currency;

        $window.swal({
          html: 'Esta seguro de hacer un deposito por: <br>'+
          '<strong>'+accounting.formatMoney(vm.deposit.monto, 'Bs. ',2,'.',',')+'</strong>',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Continuar',
          cancelButtonText: 'Cancelar',
          allowOutsideClick: false,
        }).then(function(result){
          if (result.value) {
            $cashierService.requestBalance(vm.deposit)
            .then(function(res){
              vm.send = false;
              vm.deposit = undefined;
              $state.go('lobbyUser');
              $window.swal('Aviso',res.message,'success');
            }, function(err){
              vm.send = false;
              $window.swal('Aviso',err.data.message,'warning');
            })
          }else{
            vm.sendData = false;
            return;
          }
        })
        
          
        
      }else{
        vm.send = false;
        $window.swal('Error','El monto minimo para deposito es de '+vm.default.monto_minimo_deposito,'warning');
      }
    }

    vm.findSettingSolicitud =function (){
      vm.default = _.findWhere(vm.allSettingsCashier, {currency: vm.cashierSelected.currency});

      vm.bancos_operadora = _.reduce(vm.allBancosOperadora, function(memo, data){
        var exist = _.contains(data.currencies, parseInt(vm.cashierSelected.moneda_id));
        if(exist){
          data.active = false;
          data.otros = angular.fromJson(data.otros);
          memo.push(data);
        }
        return memo;
      },[]);
    }

    vm.activeBank = function(bank){
      var SearchBank = _.findWhere(vm.bancos_operadora, {active: true});
        if(SearchBank){
          SearchBank.active = false;
        }
      bank.active = true;
      if(vm.deposit == undefined){
        vm.deposit = {banco_receptor: null};
      }
      vm.deposit.banco_receptor = bank.banco_id;
      vm.bancoReceptorSelected = bank;
      if(vm.deposit.banco_origin != vm.deposit.banco_receptor){
        vm.showAnotherBank = true;
        vm.deposit.tipo_movimiento = 2;
      }else{
        vm.showAnotherBank = false;
        vm.deposit.tipo_movimiento = 1;
      }
    }


    function addBank(){
      var modal = $uibModal.open({
        animation: true,
        component: 'addBank',
        backdrop: 'static',
        windowClass: 'modalAddBank',
        size: 'xs',
      });
      // modal.result.then(function(res){})
    }

    $rootScope.$on('get_banks_estructura', function(evt, data){
      $miscelaneos.getBanksStructure()
      .then(function(res){
        vm.banksStructure = res;
      })
    })

  }

})();
