angular
.module('app')
.component('astrolotto', {
  template: '<div ng-include="$ctrl.urlTemplate"></div>',
  controller: AstroLottoController,
})

AstroLottoController.$inject = ['$rootScope','$uibModal','QuinticoServices','user','toastr','$timeout','$sce','$window','$scope','cashierService','$state', 'miscelaneos'];

function AstroLottoController($rootScope,$uibModal, $QuinticoServices, $user, toastr, $timeout, $sce, $window, $scope, $cashierService, $state, $miscelaneos) {
  var vm = this;
  vm.color = '#e11e26';
  vm.notBalance = false;
  vm.showSigns = true;
  vm.clear = clear;
  vm.random = random;
  vm.setAside = setAside;
  vm.showTicket = showTicket;
  vm.listCombinationsToPlay = [];
  vm.buyCombinations = buyCombinations;
  vm.deleteCombination = deleteCombination;
  vm.showCategoriesExamples = false;
  vm.producto_id = $miscelaneos.getProductIdByGame('TuQuintico');
  

  vm.$onInit = function(){
    if(window.mobileAndTabletcheck()){
      vm.urlTemplate = 'app/components/quintico/quintico-mobile.html';
    }else{
      vm.urlTemplate = 'app/components/quintico/quintico.html';
    }
    $QuinticoServices.getDraws().then(function(draws){
      var sorteos = [];
      sorteos[0] = draws
      vm.draws = _.map(sorteos, function(draw, index){
        draw.pretty_fecha_juega = moment(draw.fecha_juega,'YYYY-MM-DD').format('DD/MM/YYYY');
        index == 0 ? draw.selected = true : draw.selected = false;
        return draw;
      });
    });

    vm.examples = $QuinticoServices.getExamplesPayments();
    $QuinticoServices.getMethodsPayments()
    .then(function(res){
      vm.methods = res.datos;
    })
    vm.signs = $QuinticoServices.getSigns();
    vm.currentUser = $user.getCurrentUser();
    checkCoupons();

  }


  function buyCombinations(){
    vm.selling = true;
    var total = vm.listCombinationsToPlay.length * vm.draws[0].monto_boleto;

    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      fec: moment().format('DD/MM/YY'),
      ced: vm.currentUser.rif,
      nom: vm.currentUser.nombre,
      ts: 1,
      correo: vm.currentUser.correo,
      jug: vm.listCombinationsToPlay,
      compress: false,
      ncanje: 0,
      mcanje: 0,
      cupon: "",
      usa_cupon: false,
      app: 4,
      producto_id: vm.producto_id,
      beneficiencia: 'LOTERIA DE COJEDES',
      cda: true,
    }


    if(vm.cupon != null && total > vm.cupon.disponible){
      $window.swal({
        html: 'Modifique la juagada si desea usar el bono exacto. <br>'+
        '<strong>Monto disponible:</strong>'+vm.cupon.disponible +'<br>',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false,
      }).then(function(result){
        if (result.value) {
          $QuinticoServices.buyCombinations(data)
          .then(function(res){
            vm.selling = false;
            completeSale(res)
          }, function(err){
            // if(parseFloat(err.data.cajero_web.saldo_actual) < parseFloat(vm.draws[0].amount)){
            //   vm.notBalance = true;
            // }
            toastr.error(err.data.message)
          })
        }else{
          vm.selling = false;
          return;
        }
      })
    }else{
      $QuinticoServices.buyCombinations(data)
      .then(function(res){
        vm.selling = false;
        completeSale(res)
      }, function(err){
        // if(parseFloat(err.data.cajero_web.saldo_actual) < parseFloat(vm.draws[0].amount)){
        //   vm.notBalance = true;
        // }
        toastr.error(err.data.message)
        vm.selling = false;
      })
    }

  }

  function completeSale(res){
    switch (res.cmd) {
      case "C10":
        vm.listCombinationsToPlay = [];
        $rootScope.$broadcast('updateCajeroUser',res.cajero_web);
        if(res.cupon != null){
          $rootScope.$broadcast('updateCupon', res.cupon);
        }
        // vm.ticket = $sce.trustAsHtml(angular.copy(res.ticket.replace(/\n|\r/g, '<br>')));
        vm.ticket = $sce.trustAsHtml(angular.copy(res.ticket.replace(/\\n/g, '<br>')));
        vm.ticket_ws = angular.copy(res.ticket.replace(/\\n/g, '<br>'));
        vm.ticket_print = res.ticket;
        $timeout(function(){
          vm.showTicket(vm.ticket, vm.ticket_print, vm.ticket_ws)
        },0)
        clear()
        break;
      case "C11":
        showModalCombinations(res)
        clear()
        break;
      case "C16":
        vm.listCombinationsToPlay = [];
        clear()
        break;
      case "C12":
        toastr.error('Las combinaciones solicitadas no estan disponibles o hubo un error en la venta');
        break;
      default:
    }
  }

  function showModalCombinations(response){

    var modal = $uibModal.open({
      animation: true,
      component: 'modalCombiations',
      backdrop: 'static',
      windowClass: 'modalCombinations',
      size: 'xs',
      resolve: {
        response: function() {
          return response;
        },
        listCombinationsToPlay: function(){
          return vm.listCombinationsToPlay
        },
        producto_id: function(){
          return vm.producto_id;
        }
      },
    });
    modal.result.then(function(res){
      completeSale(res)
    }, function(res2){
      toastr.info('Compra Cancelada')
      completeSale({cmd: 'C16'})
    })
  }

  function setAside(combination){
    var combinacion = parseFloat(combination) > 0 ? combination : vm.combination

    if(combinacion != undefined){
      var combinations = _.reduce(vm.signs, function(memo, sign, index){

        if(sign.checked){
          memo.push({
            i: index++,
            n: combinacion,
            s: sign.code,
            c: vm.draws[0].id,
            m: vm.draws[0].monto_boleto
          });
        }
        return memo;
      },[]);
      clear()

      vm.listCombinationsToPlay = vm.listCombinationsToPlay.concat(combinations)
    }


  }

  function deleteCombination(index, combination){
    vm.listCombinationsToPlay.splice(index, 1)
  }



  function showTicket(ticket, ticket_print,ticket_ws){
    var modal = $uibModal.open({
      animation: true,
      component: 'gModalTicket',
      backdrop: 'static',
      windowClass: 'modalTicket',
      size: 'xs',
      resolve: {
        ticket: function() {
          return ticket;
        },
        ticket_print: function(){
          return ticket_print;
        },
        ticket_ws: function(){
          return ticket_ws;
        }
      },
    });
  }

    function clear(){
      vm.combination = undefined;
      _.forEach(vm.signs, function(sign){
        sign.checked = false;
      })
      if(window.mobileAndTabletcheck()){
        vm.showSigns = true;
      }
    }

    function random(){
      _.findWhere(vm.signs, {code: _.random(1,12)}).checked = true
      setAside(generateRand())
      if(window.mobileAndTabletcheck()){
        toastr.info('Combinacion agregada')
      }
    }

    function generateRand(){
      var rand;
      var rand1;
      var rand2;
      var rand3;
      var rand4;
      var rand5;

      rand1 = _.random(0,35).toString().padStart(2, '0');
      rand2 = _.random(0,35).toString().padStart(2, '0');
      rand3 = _.random(0,35).toString().padStart(2, '0');
      rand4 = _.random(0,35).toString().padStart(2, '0');
      rand5 = _.random(0,35).toString().padStart(2, '0');
      rand = rand1 + rand2 + rand3 + rand4 + rand5;
      
      return rand.toString();
      
    }


    vm.allsigns = function(){
      if(vm.combination != undefined){
        if(vm.combination.toString().split('').length < 5){
          toastr.warning('Complete la combinacion');
          return
        }else{
          _.forEach(vm.signs, function(data){data.checked = true;});
          setAside();
        }
      }else{
        toastr.warning('Introduzca una combinacion');
        return;
      }
    }

    vm.complete = function(){
      switch (vm.combination.split('').length) {
        case 1:
          var complete = ''+_.random(0,9)+_.random(0,9)+_.random(0,9)+_.random(0,9)+''
          vm.combination = vm.combination+complete;
          break;
        case 2:
        var complete = ''+_.random(0,9)+_.random(0,9)+_.random(0,9)+''
        vm.combination = vm.combination+complete;
          break;
        case 3:
        var complete = ''+_.random(0,9)+_.random(0,9)+''
        vm.combination = vm.combination+complete;
        break;
        case 4:
        var complete = ''+_.random(0,9)+''
        vm.combination = vm.combination+complete;
        break;
        default:

      }
    }

    function checkCoupons(){
      $cashierService.getCoupon()
      .then(function(res){
        vm.cupon = res;
        if(!moment(vm.draws[0].fecha_juega, 'YYYY-MM-DD').isAfter()){
          if(parseFloat(vm.currentUser.cajero.saldo_actual) < parseFloat(vm.draws[0].monto_boleto) && vm.cupon == null){
            // vm.notBalance = true;
            vm.errorMessage = 'Recarge su saldo para continuar jugando';
          }else{
            vm.amount = angular.copy(vm.draws[0].monto_boleto);
          }
        }else{
          // vm.notBalance = true;
          vm.errorMessage = 'Recarge saldo';
        }
      })
    }

    window.getRandomCombinations = function(number){
      for (var i = 0; i < number; i++) {
        vm.random();
      }
    }

    $rootScope.$on('openModalSings', function(){
      vm.openModalSing();
    });

    $rootScope.$emit('updateNameState', {state: $state.current.name});
  }
