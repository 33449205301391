(function () {
  'use strict';
  angular
  .module('app')
  .component('addBank', {
    templateUrl: 'app/components/add-bank/add-bank.html',
    controller: AddBankController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  AddBankController.$inject = ['user','toastr','miscelaneos','$rootScope','$window'];

  function AddBankController($user, toastr, $miscelaneos, $rootScope, $window) {
    var vm = this;
    vm.save = save;
    vm.bank = {banco_id: null};


    vm.$onInit = function() {
      vm.currentUser = $user.getCurrentUser();
      $miscelaneos.getBancos()
      .then(function(res){
        vm.banks = res;
      })
      vm.accountType = [{tipo:'Corriente', key: 'corriente'},{tipo: 'Ahorro', key: 'ahorro'},{tipo: 'Pago Movil', key: 'pago_movil'}];
      if(vm.resolve.bank){
        vm.bank = vm.resolve.bank;
        vm.currentType = _.findWhere(vm.accountType, {tipo: vm.bank.tipo_cuenta});
        vm.bankSelected = vm.bank.banco;
      }else{
        vm.bank.identificacion = vm.currentUser.rif;
        vm.bank.titular = vm.currentUser.nombre;
      }

      
    }

    function save(){
      if(vm.currentUser.cajero.es_credito){
        createBank()
      }else{
        if(vm.currentUser.rif == vm.bank.identificacion){
          createBank()
        }else{
          $window.swal('Error en los datos','El Rif o Cedula deben coincidir con los datos suministrados por el usuario','error');
        }
      }

    }
    vm.setBanco = function(){
      vm.bank.banco_id = vm.bankSelected.id;
      if(vm.currentType.key != 'pago_movil'){
        vm.bank.numero_cuenta = angular.copy(vm.bankSelected.codigo)
      }
    }


    function createBank(){
      if(vm.bank.id){
        $miscelaneos.updateBankStructure(vm.bank.id, vm.bank)
        .then(function(res){
          vm.close({$value: ''});
          $rootScope.$emit('get_banks_estructura');
          toastr.success('Exito',res.message,'success');
        }, function(err){
          vm.close({$value: ''});
          toastr.error('error',err.data.message,'error');
        })
      }else{
        $miscelaneos.addBankStructure(vm.bank)
        .then(function(res){
          vm.close({$value: ''});
          $rootScope.$emit('get_banks_estructura');
          toastr.success('Exito',res.message,'success');
        }, function(err){
          vm.close({$value: ''});
          toastr.error('error',err.data.message,'error');
        })


      }
    }



  }

})();
