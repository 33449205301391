(function() {
  'use strict';
  angular
    .module('app')
    .factory('loteriesService', loteriesService);

  loteriesService.$inject = ['$http', 'api'];

  function loteriesService($http, api) {

    function hashToParams(params) {
      if(params){
        var params_url = '';
  
        Object.keys(params).forEach(function (item, index) {
          if (index == 0 && params[item] != null) {
            params_url += '?' + item + '=' + params[item];
          } else if (index != 0 && params[item] != null) {
            params_url += '&' + item + '=' + params[item];
          }
        });
        return params_url;
      }else{
        return null
      }

    }

    function sale(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/nueva_venta_v2'), data)
      .then(function(res){
        return res.data;
      })
    }

    function findCliente(cedula) {
      return $http.get(api.getUrlVentaCentinela('clientes/'+cedula))
      .then(function(res){
        return res.data;
      })
    }

    function confirmSale(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/confirmar_venta'), data)
      .then(function(res){
        return res.data;
      })
    }

    function reverTicket(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/reversar_venta'), data)
      .then(function(res){
        return res.data;
      })
    }

    function listLoterias(){
      return $http.get(api.getApiSPJ('loterias'))
      .then(function(res){
        return res.data;
      })
    }

    function consultResultados(data){
      return $http.get(api.getApiSPJ('loterias/resultados'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    return {
      sale: sale,
      confirmSale: confirmSale,
      reverTicket: reverTicket,
      findCliente: findCliente,
      listLoterias: listLoterias,
      consultResultados: consultResultados
    };

  }
})();
