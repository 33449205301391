(function () {
  'use strict';
  angular
  .module('app')
  .component('taquillas', {
    templateUrl: 'app/components/taquillas/taquillas.html',
    controller: TaquillasController,
  });

  TaquillasController.$inject = ['$window', 'user','toastr','agentsPanelService','$rootScope','$stateParams','$state','cashierService'];

  function TaquillasController($window, $user, toastr, $agentsPanelService, $rootScope, $stateParams, $state,cashierService) {
    var vm = this;
    vm.saveEstacion = saveEstacion;
    vm.loading = true;
    
    
    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
      vm.listTaquilla = false;
      

      $agentsPanelService.getMyPointSales()
      .then(function(res){
        vm.point_sales = res;
        vm.loading = false;
      })
      
      
      if($stateParams.taquilla){
        $agentsPanelService.taquillas.get({id: parseInt($stateParams.taquilla)}).$promise
        .then(function(res){
          vm.taquilla = res;
          vm.taquilla.username = res.usuarios[0].username;
        })
      }
    }

    vm.listPointSale = function(){
      vm.loading = true;
      $agentsPanelService.taquillas.query({padre_id: vm.listPointSaleSelected.id}).$promise.then(function(res){
        vm.taquillas = res;          
        vm.loading = false;
      })
    }

    vm.iniTaquilla = function(){
      vm.cantTaquillas = vm.taquillas.length;
      vm.listTaquilla = !vm.listTaquilla;
      vm.taquilla = {
        nombre: '',
        username: '',
        estaciones: []
      }
    }

    vm.editTaquilla = function(taquilla){
      console.log(taquilla)
      vm.pointSaleSelected = vm.listPointSaleSelected;
      vm.listTaquilla = !vm.listTaquilla;
      vm.taquilla = {
        id: taquilla.id,
        nombre: taquilla.nombre,
        username: taquilla.usuarios[0].username,
        estaciones: taquilla.childs
      }
    }

    vm.addEstacion = function(){
      vm.cantTaquillas += 1;
      vm.taquilla.estaciones.push({
        nombre: '',
        username: ''
      });
    }

    function saveEstacion(){
      vm.loading = true;
      var data = {
        padre_id: vm.pointSaleSelected.id,
        nombre: vm.taquilla.nombre,
        usuario: {
          username: vm.taquilla.username,
          password: vm.taquilla.password,
          activo: true
        },
        activo: true,
        estaciones: vm.taquilla.estaciones
      }

      if(vm.taquilla.id){
        data.estructura_id = vm.taquilla.id;
      }

      

      if(vm.taquilla.id){
        data.id = vm.taquilla.id;
        $agentsPanelService.taquillas.update({id: vm.taquilla.id}, data, function(res){
          vm.loading = false;
          vm.estacion = undefined;
          vm.listTaquilla = false;
          vm.listPointSaleSelected = undefined;
          vm.point_sales = []
          vm.$onInit();
          toastr.success(res.message);
          $state.go('taquillas', {action: 'taquillas', estacion: null});
        }, function(err){
          toastr.success(res.data.message);
          vm.loading = false;
          vm.estacion = undefined;
          vm.listTaquilla = false;
          vm.listPointSaleSelected = undefined;
          vm.point_sales = []
          vm.$onInit();
        });
      }else{
        $agentsPanelService.taquillas.save(data).$promise.then(function(res){
          vm.loading = false;
          vm.estacion = undefined;
          vm.listTaquilla = false;
          vm.point_sales = []
          toastr.success(res.message);
          $state.go('taquillas', {action: 'taquillas', estacion: null});
        }, function(err){
          toastr.success(res.data.message);
        });
      }
      
    }


  }

})();
