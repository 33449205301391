angular
.module('app')
.component('footer', {
  templateUrl: 'app/components/footer/footer.html',
  controller: FooterController
});

FooterController.$inject = ['user']

function FooterController(user) {
  var vm = this;
  vm.showFooter = true;
  vm.current_user = user.getCurrentUser();
  console.log(vm.current_user)
  vm.year = moment().format("YYYY");


  
}
