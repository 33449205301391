(function () {
  'use strict';
  angular
  .module('app')
  .component('lobbyComponent', {
    templateUrl: 'app/components/lobby/lobby.html',
    controller: MainComponent,
    bindings: {
      productsAvailables: '<'
    }
  });

  MainComponent.$inject = ['$http', 'url', 'user','toastr', '$state','$rootScope','ActionCableChannel','$window'];

  function MainComponent($http, $url, $user, toastr, $state, $rootScope, ActionCableChannel, $window) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function() {
      vm.currentUser.cajero = undefined;
      $rootScope.$broadcast('updatedUser', vm.currentUser);
    }

    vm.taquillaConstruccion = function(){
      $window.swal('Alto','Juego en Construccion', 'warning')
    }
    
    $rootScope.$on('updatedUser',function(event,data){
      vm.currentUser = data;
    });

    $rootScope.$on('updateCajeroUser', function(evt, data){
      delete vm.currentUser.cajero
      vm.currentUser.cajero = data;
      $user.setCurrentUser(vm.currentUser);
      console.log(vm.currentUser.cajero)
    })

    $rootScope.$emit('updateNameState', {state: $state.current.name});
  }

})();
