(function () {
  'use strict';
  angular
  .module('app')
  .component('resultados', {
    template: '<div ng-include="$ctrl.urlTemplate"></div>',
    controller: ResultadosController,
  });

  ResultadosController.$inject = ['user','agentsPanelService','loteriesService','$sce', '$uibModal','$timeout'];

  function ResultadosController($user, $agentsPanelService, $loteriesService,$sce, $uibModal,$timeout) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      vm.urlTemplate = 'app/components/resultados/resultados.html';
      vm.from = moment().format('YYYY-MM-DD');
      vm.loading = true;
      $loteriesService.listLoterias()
      .then(function(res){
        console.log(res)
        vm.loading = false;
        vm.loterias = res;
        vm.loteriasSelected = vm.loterias[0];
      })
    }

    vm.consult = function(){
      vm.loading = true;
      var data = {
        loteria_id: vm.loteriasSelected.id,
        date: moment(vm.date).format('YYYY-MM-DD')
      }
      $loteriesService.consultResultados(data)
      .then(function(res){
        vm.loading = false;
        vm.resultados = res.data;
        vm.print = res.print;
      })
      
    }

    vm.printReport = function(){
      vm.ticket = $sce.trustAsHtml(angular.copy(vm.print.replace(/\n|\r/g, '<br>')));
      vm.ticket_ws = angular.copy(vm.print.replace(/\\n/g, '<br>'));
      vm.ticket_print = angular.copy(vm.print.replace(/\n|\r/g, '\n'));
      $timeout(function () {
        vm.showTicket(vm.ticket, vm.ticket_print, vm.ticket_ws, null, null)
      }, 0)
    }

    vm.showTicket = function(ticket, ticket_print, ticket_ws, codagen_info, tracking_id) {
      var modal = $uibModal.open({
        animation: true,
        component: 'gModalTicket',
        backdrop: 'static',
        windowClass: 'modalTicket',
        size: 'xs',
        resolve: {
          ticket: function () {
            return ticket;
          },
          ticket_print: function () {
            return ticket_print;
          },
          ticket_ws: function () {
            return ticket_ws;
          },
          codagen_info: function(){
            return codagen_info;
          },
          tracking_id: function(){
            return tracking_id 
          }
        },
      })
    }


    vm.today = function() {
      vm.date = new Date();
      vm.date_to = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.dt = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      // maxDate: new Date(2020, 5, 22),
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false,
      showButtonBar: false,
    };

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };
    vm.toggleMin();

    vm.open1 = function() {
      vm.popup1.opened = true;
    };

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.dt = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup1 = {
      opened: false
    };

    vm.popup2 = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

    
  }

})();
