(function() {
  'use strict';
  angular
    .module('app')
    .factory('noWinningService', noWinningService);

  noWinningService.$inject = ['$http', 'api'];

  function noWinningService($http, api) {

    function addticket(data) {
      return $http.post(api.getUrlVentaCentinela('non_winning/add_ticket'), data)
      .then(function(res){
        return res.data;
      })
    }
    

    return {
      addticket: addticket
    };

  }
})();
