angular
.module('app')
.component('modalSigns', {
  templateUrl: 'app/components/modals/modalSigns.html',
  controller: ModalSingsController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

ModalSingsController.$inject = ['hotkeys'];

function ModalSingsController(hotkeys){
  var vm = this;
  vm.message = 'Hello world';
  vm.toggleAll = toggleAll;
  vm.cancel = cancel;
  vm.selectSigns = selectSigns;
  
  vm.$onInit = function(){
    vm.signs = vm.resolve.signs;
    console.log(vm.signs);
  }

  function toggleAll(){
    _.forEach(vm.signs, function(signo){
      signo.checked = !signo.checked;
    });
  }

  function selectSigns(){
    vm.close({$value: vm.signs});
  }

  function cancel(){
    vm.dismiss({$value: 'cancel'});
  }

  hotkeys.add({
    combo: '1',
    description: 'This one goes to 11',
    callback: function() {
      var index = 1 - 1;
      vm.signs[index].checked = !vm.signs[index].checked;
    }
  });

  hotkeys.add({
    combo: '2',
    description: 'This one goes to 11',
    callback: function() {
      var index = 2 - 1;
      vm.signs[index].checked = !vm.signs[index].checked;
    }
  });

  hotkeys.add({
    combo: '3',
    description: 'This one goes to 11',
    callback: function() {
      var index = 3 - 1;
      vm.signs[index].checked = !vm.signs[index].checked;
    }
  });

  hotkeys.add({
    combo: '4',
    description: 'This one goes to 11',
    callback: function() {
      var index = 4 - 1;
      vm.signs[index].checked = !vm.signs[index].checked;
    }
  });

  hotkeys.add({
    combo: '5',
    description: 'This one goes to 11',
    callback: function() {
      var index = 5 - 1;
      vm.signs[index].checked = !vm.signs[index].checked;
    }
  });

  hotkeys.add({
    combo: '6',
    description: 'This one goes to 11',
    callback: function() {
      var index = 6 - 1;
      vm.signs[index].checked = !vm.signs[index].checked;
    }
  });

  hotkeys.add({
    combo: '7',
    description: 'This one goes to 11',
    callback: function() {
      var index = 7 - 1;
      vm.signs[index].checked = !vm.signs[index].checked;
    }
  });

  hotkeys.add({
    combo: '8',
    description: 'This one goes to 11',
    callback: function() {
      var index = 8 - 1;
      vm.signs[index].checked = !vm.signs[index].checked;
    }
  });

  hotkeys.add({
    combo: '9',
    description: 'This one goes to 11',
    callback: function() {
      var index = 9 - 1;
      vm.signs[index].checked = !vm.signs[index].checked;
    }
  });
  
  hotkeys.add({
    combo: '/',
    description: 'This one goes to 11',
    callback: function() {
      var index = 10 - 1;
      vm.signs[index].checked = !vm.signs[index].checked;
    }
  });

  hotkeys.add({
    combo: '*',
    description: 'This one goes to 11',
    callback: function() {
      var index = 11 - 1;
      vm.signs[index].checked = !vm.signs[index].checked;
    }
  });

  hotkeys.add({
    combo: '-',
    description: 'This one goes to 11',
    callback: function() {
      var index = 12 - 1;
      vm.signs[index].checked = !vm.signs[index].checked;
    }
  });

  hotkeys.add({
    combo: '+',
    description: 'This one goes to 11',
    callback: function() {
      vm.selectSigns();
    }
  });

}
