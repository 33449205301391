angular
  .module('app')
  .filter('imageUrlSPJ', imageUrlSPJ);

function imageUrlSPJ(BASE_URL) {

  return function (relativePath, defaultPath) {
    return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6v-Quj0rUbKfkYkO5xry7QsyV_3dNemjlbw&usqp=CAU';
    // transform the item and return it
    if (relativePath === null) {// cargando o image-null
      return defaultPath || '/images/user-tie.png';
    }
    if(relativePath){
      if (relativePath[0] === 'h') {// si comienza por http es absoluta
        return relativePath || (BASE_URL + relativePath);
      }
      return BASE_URL + relativePath;
    }
  };
}
