angular
.module('app')
.component('modalPagoMovil', {
  templateUrl: 'app/components/modals/modalPagoMovil.html',
  controller: ModalPagoMovilController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

ModalPagoMovilController.$inject = ['miscelaneos','loteriesService'];

function ModalPagoMovilController($miscelaneos, $loteriesService){
  var vm = this;
  vm.cancel = cancel;
  vm.save = save;
  vm.findCliente = findCliente;
  
  vm.$onInit = function(){
    $miscelaneos.getBancos()
      .then(function(res){
        vm.banks = res;
      })
  }

  

  function save(){
    vm.pagoMovil.telefono = vm.pagoMovil.telefono.split('-').join('')
    vm.close({$value: vm.pagoMovil});
  }

  function cancel(){
    vm.dismiss({$value: 'cancel'});
  }

  function findCliente(){
    $loteriesService.findCliente(vm.pagoMovil.cedula)
    .then(function(res){
      vm.pagoMovil = res;
      vm.bankSelected = _.findWhere(vm.banks, {id: vm.pagoMovil.banco_id})
    })
  }

  

}
