angular
.module('app')
.component('granjazo', {
  template: '<div ng-include="$ctrl.urlTemplate"></div>',
  controller: GranajazoController,
  bindings: {
    drawsZoo: '<',
    animalitosGranjazo: '<'
  }
});

GranajazoController.$inject = ['user', 'animalitoService', 'miscelaneos'];

function GranajazoController($user, $animalitoService, $miscelaneos) {
  var vm = this;
  vm.color = '#54a23f';
  vm.countDraw;
  vm.amount = undefined;
  vm.plays = [];
  vm.step1 = true;
  vm.step2 = false;
  vm.step3 = false;
  vm.showAnimals = true;
  vm.drawsWithPlays = [];
  vm.producto_id = $miscelaneos.getProductIdByGame('Granjazo');
  console.log(vm.producto_id, 'producto_id')
  vm.tabPanel = 'first';
  vm.date = moment().format('DD/MM/YY');
  var day = moment().format('d');
  var hour = moment().format('H:mm');


  vm.$onInit = function(){
    vm.loading = true;
    vm.currentUser = $user.getCurrentUser();

    if(window.mobileAndTabletcheck()){
      vm.urlTemplate = 'app/components/granjazo/granjazo-mobile.html';
    }else{
      vm.urlTemplate = 'app/components/granjazo/granjazo.html';
    }

  }

}
