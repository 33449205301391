angular
.module('app')
.component('gModalTicket', {
  // templateUrl: 'app/components/global-modals/modal-ticket.html',
  template: '<div ng-include="$ctrl.urlTemplate"></div>',
  controller: GModalTicketController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

GModalTicketController.$inject = ['$window','user', '$timeout','hotkeys','agentsPanelService','$state'];

function GModalTicketController($window, $user, $timeout, hotkeys, $agentsPanelService, $state){
  var vm = this;
  vm.cancel = cancel;
  vm.copyClipboard = copyClipboard;

  vm.$onInit = function(){
    vm.currentUser = $user.getCurrentUser();
    vm.currentState = $state.current.name;
    vm.ticket = vm.resolve.ticket;
    vm.ticket_print = vm.resolve.ticket_print;
    vm.sendTicket = sendTicket;
    vm.ticket_ws = vm.resolve.ticket_ws;
    console.log(vm.resolve.template)
    if(vm.resolve.template == undefined){
      vm.urlTemplate = 'app/components/global-modals/modal-ticket.html';
    } else if(vm.resolve.template == 1) {
      vm.urlTemplate = 'app/components/global-modals/modal-ticket-2.html';
    } else if (vm.resolve.template == 3) {
      vm.urlTemplate = 'app/components/global-modals/modal-ticket-3.html';
    }
    if(vm.resolve.tracking_id){
      vm.tracking_id = vm.resolve.tracking_id;
    }
    if (vm.resolve.codagen_info){
      vm.codagen_info = vm.resolve.codagen_info;
    }
  }

  function cancel(){
    vm.dismiss({$value: 'cancel'});
  }

  function sendTicket(){
    var newTicket = vm.ticket_ws.split('<br>');
    var ticket = newTicket.join('%0a');
    
    if(window.mobileAndTabletcheck()){
      $window.location = "https://api.whatsapp.com/send?phone=+58"+vm.phone+"&text="+ticket;
    }else{
      window.open("https://web.whatsapp.com/send?phone=+58"+vm.phone+"&text="+ticket,'_blank')
    }
  }

  function copyClipboard(){
    var newTicket = vm.ticket_ws.split('<br>');
    var ticket = newTicket.join('\n');

    var el = document.createElement('textarea');
    el.value = ticket;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    $window.swal('Texto Copiado','Ticket copiado en el portapapeles con exito','info');
  }

  hotkeys.add({
    combo: '+',
    description: 'This one goes to 11',
    callback: function() {
      vm.print();
    }
  });

  vm.printTicket = function(){
    $agentsPanelService.printTicket({tracking_id: vm.tracking_id})
    .then(function(res){
      vm.print();
    }, function(err){
      $window.swal('Error',err.data.message,'error');
    })
  }

  vm.borrarTicket = function(){
    $window.swal({
      html: 'Esta seguro de anular el ticket?',
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false,
    }).then(function(result){
      if (result.value) {
        $agentsPanelService.borrarTicket({tracking_id: vm.tracking_id })
        .then(function(res){
          vm.dismiss({$value: false});
          swal('Exito','Ticket borrado con exito','success');
        }, function(err){
          vm.send = false;
          $window.swal('Error',err.data.message,'warning');
        })
      }else{
        vm.dismiss({$value: false});
        return;
      }
    })
  }

  vm.print = function(){
    try{

      // Crear una instancia del WebSocket
      const socket = new WebSocket('ws://127.0.0.1:1315');

      // Evento que se dispara cuando la conexión se establece correctamente
      socket.onopen = function() {
        console.log('Conexión establecida.');

        // Enviar un mensaje al servidor
        // const mensaje = '01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n01\n';
        console.log(vm.ticket_print);
        socket.send(vm.ticket_print);
        $timeout(function(){
          socket.send('\n');
          socket.send('\n');
          socket.send('\n');
          socket.send('\n');
          console.log('cut')
          socket.send('cut');
          socket.close();
          vm.close({$value: true})
        },0)
        
      };

      // Evento que se dispara cuando se recibe un mensaje del servidor
      socket.onmessage = function(event) {
        console.log('Mensaje recibido del servidor:', event.data);
      };

      // Evento que se dispara cuando se produce un error en la conexión
      socket.onerror = function(error) {
        console.error('Error en la conexión:', error);
      };

      // Evento que se dispara cuando la conexión se cierra
      socket.onclose = function(event) {
        console.log('Conexión cerrada:', event.code, event.reason);
      };

    }catch(e){
      alert(JSON.stringify(e));
    }
  }

}
