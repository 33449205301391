angular
.module('app')
.config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider) {
  $httpProvider.interceptors.push(['$q', 'user', '$rootScope','$window','$state', function ($q, user, $rootScope, $window, $state) {
    return {
      request: function(config) {
        var currentUser = user.getCurrentUser();

        if(currentUser){
          config.headers['centrodeapuestas'] = true;
          config.headers['tokenSPJ'] = currentUser.usuario.token;
        }
        config.headers['subdomain'] = 'dataweb';
        return config;
      },
      responseError: function(rejection) {
        if (rejection.status == 401) {
          $rootScope.$broadcast('UNAUTHORIZED');
          $window.swal('Info',rejection.data.message,'error');
        }
        return $q.reject(rejection);
      }
    };
  }]);


  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
  .state('webLayout', {
    abstract: true,
    component: 'webLayout'
  })
  .state('home', {
    parent: 'webLayout',
    url: '/',
    component: 'auth',
    onEnter: function($location, $state, user){
      if(user.getCurrentUser()){
        $state.go('lobbyHome')
      }else{
        if($location.host() == 'localhost' || $location.host() == 'testcda.com' || $location.host() == 'www.testcda.com'){
          $state.go('auth',{cdapuestas: true});
        }
      }
    }
  })
  .state('singup', {
    parent: 'webLayout',
    url: '/registro?tipo_de_registro&promotional_code',
    component: 'singup'
  })
  .state('confirm', {
    parent: 'webLayout',
    url: '/confirm_customer/:token',
    component: 'confirmCustomer'
  })
  .state('auth', {
    parent: 'webLayout',
    url: '/login/:cdapuestas?',
    component: 'auth'
  })
  .state('forgotPassword', {
    parent: 'webLayout',
    url: '/olvide-mi-contrasena',
    component: 'forgotPassword'
  })
  .state('lobby', {
    parent: 'agentspanel',
    url: '/lobby',
    component: 'lobbyComponent',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
      productsAvailables: [
        '$q',
        'miscelaneos',
        'availableProducts',
        function($q, $miscelaneos, $availableProducts) {
          var defer = $q.defer();
          if($availableProducts.getCurrentProducts()){
            var products = angular.copy($availableProducts.getCurrentProducts());
            if(products){
              defer.resolve(products)
              return defer.promise;
            }else{
              defer.resolve();
              return defer.promise;
            }
          }
        },
      ]
    }
  })
  .state('lobbyUser', {
    parent: 'agentspanel',
    url: '/area-de-usuario/:cashier_id?',
    component: 'lobbyUser',
    resolve: {
      currentUser: [
          '$q',
          'user',
          function ($q, user) {
            if (user.getCurrentUser()) {
              return $q.resolve(user);
            }
            return $q.reject('UNAUTHORIZED');
          }
        ]
    }
  })
  .state('lobbyHome', {
    parent: 'agentspanel',
    url: '/area-de-usuario',
    component: 'lobbyHome',
    resolve: {
      currentUser: [
          '$q',
          'user',
          function ($q, user) {
            if (user.getCurrentUser()) {
              return $q.resolve(user);
            }
            return $q.reject('UNAUTHORIZED');
          }
        ]
    }
  })
  .state('deposit', {
    parent: 'agentspanel',
    url: '/area-de-usuario/depositos/:moneda_id?',
    component: 'deposit',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('tutoDeposit', {
    parent: 'webLayout',
    url: '/como-depositar',
    component: 'tutoDeposit',
  })
  .state('addBank', {
    parent: 'webLayout',
    url: '/area-de-usuario/bancos/agregar-bancos',
    component: 'addBank',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('jungla', {
    parent: 'webLayout',
    url: '/animalitos/jungla-millonaria/:cashier_id?',
    component: 'jungla',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
      drawsZoo: [
        '$q',
        'miscelaneos',
        'user',
        function($q, $miscelaneos, $user) {
          var defer = $q.defer();
          var currentHora = parseInt(moment().format('Hmm'))
          if($user.getCurrentUser()){
            var drawsZoo = _.findWhere($miscelaneos.getAndUncompressDraws(), {id: 15})
            if(drawsZoo){
              defer.resolve(_.reduce(drawsZoo.sorteos, function(memo,data){
                var horaSorteo = parseInt(data.horac.split(':').join(''))
                if(horaSorteo > currentHora){
                  data.checked = false;
                  memo.push(data);
                }
                return memo;
              },[]))
              return defer.promise;
            }else{
              defer.resolve();
              return defer.promise;
            }
          }
        },
      ]
    },
  })
  .state('granjita', {
    parent: 'webLayout',
    url: '/animalitos/la-granjita',
    component: 'granjita',
    name: 'La Granjita',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
      drawsZoo: [
        '$q',
        'miscelaneos',
        'user',
        function($q, $miscelaneos, $user) {
          var defer = $q.defer();
          var currentHora = parseInt(moment().format('Hmm'))
          if($user.getCurrentUser()){
            var drawsZoo = _.findWhere($miscelaneos.getAndUncompressDraws(), {id: 17})
            if(drawsZoo){
              defer.resolve(_.reduce(drawsZoo.sorteos, function(memo,data){
                var horaSorteo = parseInt(data.horac.split(':').join(''))
                if(horaSorteo > currentHora){
                  data.checked = false;
                  memo.push(data);
                }
                return memo;
              },[]))
              return defer.promise;
            }else{
              defer.resolve();
              return defer.promise;
            }
          }
        },
      ]
    },
  })
  .state('lottoActivo', {
    parent: 'webLayout',
    url: '/animalitos/lotto-activo',
    component: 'lottoActivo',
    name: 'Lotto Activo',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
      drawsZoo: [
        '$q',
        'miscelaneos',
        'user',
        function($q, $miscelaneos, $user) {
          var defer = $q.defer();
          var currentHora = parseInt(moment().format('Hmm'))
          var current_user = $user.getCurrentUser();
          if(current_user){
            var id_sorteo = current_user.tipo == 4 ? 19 : 18;
            var drawsZoo = _.findWhere($miscelaneos.getAndUncompressDraws(), {id: id_sorteo})
            if(drawsZoo){
              defer.resolve(_.reduce(drawsZoo.sorteos, function(memo,data){
                var horaSorteo = parseInt(data.horac.split(':').join(''))
                if(horaSorteo > currentHora){
                  data.checked = false;
                  memo.push(data);
                }
                return memo;
              },[]))
              return defer.promise;
            }else{
              defer.resolve();
              return defer.promise;
            }
          }
        },
      ]
    },
  })
  .state('firstRun', {
    parent: 'webLayout',
    url: '/apuestas-deportivas/first-run',
    component: 'firstRun',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
      drawsZoo: [
        '$q',
        'miscelaneos',
        'user',
        function($q, $miscelaneos, $user) {
          var defer = $q.defer();
          var currentHora = parseInt(moment().format('Hmm'))
          if($user.getCurrentUser()){
            var drawsZoo = _.findWhere($miscelaneos.getAndUncompressDraws(), {id: 15})
            if(drawsZoo){
              defer.resolve(_.reduce(drawsZoo.sorteos, function(memo,data){
                var horaSorteo = parseInt(data.horac.split(':').join(''))
                if(horaSorteo > currentHora){
                  data.checked = false;
                  memo.push(data);
                }
                return memo;
              },[]))
              return defer.promise;
            }else{
              defer.resolve();
              return defer.promise;
            }
          }
        },
      ],
      games: [
        '$q',
        'miscelaneos',
        'user',
        function($q, $miscelaneos, $user) {
          var defer = $q.defer();
          var currentHora = parseInt(moment().unix());
          var current_user = $user.getCurrentUser();
          var games = angular.fromJson(current_user.games_first_run);
          if($user.getCurrentUser()){
            if(games){
              defer.resolve(_.reduce(games, function(memo, data){
                if(currentHora < data.game_close){
                  memo.push(data)
                }
                return memo
              },[]))
              return defer.promise;
            }
          }else{
            defer.resolve();
            return defer.promise;
          }
        },
      ]
    },
  })
  .state('animalitos', {
    parent: 'webLayout',
    url: '/animalitos/todos-los-animalitos/:cashier_id?',
    component: 'animalitos',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
      drawsJungla: [
        '$q',
        'miscelaneos',
        'user',
        function($q, $miscelaneos, $user) {
          var defer = $q.defer();
          var currentHora = parseInt(moment().format('Hmm'))
          if($user.getCurrentUser()){
            var drawsZoo = _.findWhere($miscelaneos.getAndUncompressDraws(), {id: 15})
            if(drawsZoo){
              defer.resolve(_.reduce(drawsZoo.sorteos, function(memo,data){
                var horaSorteo = parseInt(data.horac.split(':').join(''))
                if(horaSorteo > currentHora){
                  data.checked = false;
                  memo.push(data);
                }
                return memo;
              },[]))
              return defer.promise;
            }else{
              defer.resolve();
              return defer.promise;
            }
          }
        },
      ],
      drawsLaGranjita: [
        '$q',
        'miscelaneos',
        'user',
        function($q, $miscelaneos, $user) {
          var defer = $q.defer();
          var currentHora = parseInt(moment().format('Hmm'))
          if($user.getCurrentUser()){
            var drawsZoo = _.findWhere($miscelaneos.getAndUncompressDraws(), {id: 17})
            if(drawsZoo){
              defer.resolve(_.reduce(drawsZoo.sorteos, function(memo,data){
                var horaSorteo = parseInt(data.horac.split(':').join(''))
                if(horaSorteo > currentHora){
                  data.checked = false;
                  memo.push(data);
                }
                return memo;
              },[]))
              return defer.promise;
            }else{
              defer.resolve();
              return defer.promise;
            }
          }
        },
      ],
      drawsLottoActivo: [
        '$q',
        'miscelaneos',
        'user',
        function($q, $miscelaneos, $user) {
          var defer = $q.defer();
          var currentHora = parseInt(moment().format('Hmm'))
          var current_user = $user.getCurrentUser();
          if(current_user){
            var id_sorteo = 18;
            var drawsZoo = _.findWhere($miscelaneos.getAndUncompressDraws(), {id: id_sorteo})
            if(drawsZoo){
              defer.resolve(_.reduce(drawsZoo.sorteos, function(memo,data){
                var horaSorteo = parseInt(data.horac.split(':').join(''))
                if(horaSorteo > currentHora){
                  data.checked = false;
                  memo.push(data);
                }
                return memo;
              },[]))
              return defer.promise;
            }else{
              defer.resolve();
              return defer.promise;
            }
          }
        },
      ],
      drawsZoologicoActivo: [
        '$q',
        'miscelaneos',
        'user',
        function($q, $miscelaneos, $user) {
          var defer = $q.defer();
          var currentHora = parseInt(moment().format('Hmm'))
          if($user.getCurrentUser()){
            var drawsZoo = _.findWhere($miscelaneos.getAndUncompressDraws(), {id: 1})
            if(drawsZoo){
              defer.resolve(_.reduce(drawsZoo.sorteos, function(memo,data){
                var horaSorteo = parseInt(data.horac.split(':').join(''))
                if(horaSorteo > currentHora){
                  data.checked = false;
                  memo.push(data);
                }
                return memo;
              },[]))
              return defer.promise;
            }else{
              defer.resolve();
              return defer.promise;
            }
          }
        },
      ],
      drawsGranjaMillonaria: [
        '$q',
        'miscelaneos',
        'user',
        function($q, $miscelaneos, $user) {
          var defer = $q.defer();
          var currentHora = parseInt(moment().format('Hmm'))
          if($user.getCurrentUser()){
            var drawsZoo = _.findWhere($miscelaneos.getAndUncompressDraws(), {id: 21})
            if(drawsZoo){
              defer.resolve(_.reduce(drawsZoo.sorteos, function(memo,data){
                var horaSorteo = parseInt(data.horac.split(':').join(''))
                if(horaSorteo > currentHora){
                  data.checked = false;
                  memo.push(data);
                }
                return memo;
              },[]))
              return defer.promise;
            }else{
              defer.resolve();
              return defer.promise;
            }
          }
        },
      ],
      drawsGranjazo: [
        '$q',
        'miscelaneos',
        'user',
        function($q, $miscelaneos, $user) {
          var defer = $q.defer();
          var currentHora = parseInt(moment().format('Hmm'))
          if($user.getCurrentUser()){
            var drawsZoo = _.findWhere($miscelaneos.getAndUncompressDraws(), {id: 22})
            if(drawsZoo){
              defer.resolve(_.reduce(drawsZoo.sorteos, function(memo,data){
                var horaSorteo = parseInt(data.horac.split(':').join(''))
                if(horaSorteo > currentHora){
                  data.checked = false;
                  memo.push(data);
                }
                return memo;
              },[]))
              return defer.promise;
            }else{
              defer.resolve();
              return defer.promise;
            }
          }
        },
      ]
    },
  })
  .state('rifcar', {
    parent: 'webLayout',
    url: '/rifcar',
    component: 'rifcar',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
      rifas: [
        '$q',
        'user',
        function($q, $user) {
          var defer = $q.defer();
          if($user.getCurrentUser()){
            var rifas = angular.copy($user.getCurrentUser().super_juegos.rifas);
            if(rifas != null){
              defer.resolve(_.map(rifas, function(data){
                var defaultAttributes = []
                if(data.attributos != null){
                  defaultAttributes.push({key: 'Valor', value: data.valor});
                  defaultAttributes.push({key: 'Fecha', value: data.fecha});
                  data.attributos = angular.fromJson(data.attributos);
                  data.attributos = data.attributos.concat(defaultAttributes);
                }
                if(data.imagenes.length > 0){
                  data.imgPrincipal = _.findWhere(data.imagenes, {principal: true}) || data.imagenes[0];
                  data.checked = false;
                }else{
                  data.imgPrincipal = 'https://i.imgur.com/0AKb7Rp.png'
                }
                return data;
              }))
              return defer.promise;
            }else{
              defer.resolve();
              return defer.promise;
            }
          }
        },
      ]
    }
  })
  .state('astrolotto', {
    parent: 'webLayout',
    url: '/astrolotto',
    component: 'astrolotto',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('taquillaConstruccion', {
    parent: 'webLayout',
    url: '/juego-en-construccion',
    component: 'construcction',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('agentspanel', {
    parent: 'webLayout',
    url: '/panel-de-agentes',
    component: 'agentsPanel',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportAgents',{
    parent: 'agentspanel',
    url: '/reporte',
    component: 'reportAgents',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('checkout',{
    parent: 'agentspanel',
    url: '/cuadre-de-caja',
    component: 'checkout',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('estaciones',{
    parent: 'agentspanel',
    url: '/estaciones/:action/:estacion?',
    component: 'estaciones',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('taquillas',{
    parent: 'agentspanel',
    url: '/taquillas',
    component: 'taquillas',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('printView',{
    parent: 'agentspanel',
    url: '/tickets-pendientes-por-impresion',
    component: 'printView',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('tickets',{
    parent: 'agentspanel',
    url: '/mis-tickets',
    component: 'tickets',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('payTicket',{
    parent: 'agentspanel',
    url: '/pago-de-tickets',
    component: 'payTicket',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('anulTicket',{
    parent: 'agentspanel',
    url: '/anular-tickets',
    component: 'anulTicket',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('pointSales',{
    parent: 'agentspanel',
    url: '/agencias/:action/:point_sales?',
    component: 'pointSales',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('subAgents',{
    parent: 'agentspanel',
    url: '/:action',
    component: 'subAgents',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('suggested',{
    parent: 'agentspanel',
    url: '/mis-sugeridos',
    component: 'suggested',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('pageMarketer',{
    parent: 'agentspanel',
    url: '/mi-codigo-promocional',
    component: 'pageMarketer',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('myBonus',{
    parent: 'agentspanel',
    url: '/mis-bonos',
    component: 'myBonus',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  }).state('profile',{
    parent: 'webLayout',
    url: '/mi-perfil',
    component: 'profile',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('messages',{
    parent: 'webLayout',
    url: '/mis-mensajes',
    component: 'message',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  }).state('loteries',{
    parent: 'webLayout',
    url: '/loterias/:cashier_id?',
    component: 'loteries',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
      drawsLoteries: [
        '$q',
        'miscelaneos',
        'user',
        function($q, $miscelaneos, $user) {
          var defer = $q.defer();
          var currentHora = parseInt(moment().format('Hmm'))
          if($user.getCurrentUser()){
            var draws = _.reduce($miscelaneos.getAndUncompressDraws(), function(memo, data){
              if(data.tipo == 'triples'){
                memo.push(data);
              }
              return memo;
            },[]);
            if(draws){
              defer.resolve(_.forEach(draws, function(data){
                _.map(data.sorteos, function(sorteo){
                  var hora = parseInt(sorteo.horac.split(':').join(''));
                  sorteo.name = '';
                  if(sorteo.tipo == 'A' || sorteo.tipo == 'B'|| sorteo.tipo == 'C'){
                    sorteo.name = "Triple "+sorteo.tipo;
                  }
                  if(sorteo.tipo == 'Z'){
                    sorteo.name = "Zodiacal";
                  }
                  sorteo.selected = false;
                  sorteo.prettyHour = moment(sorteo.horac, 'h:mm').add(5,'minutes').format('hh:mm a')
                  if(hora < currentHora){
                    sorteo.disabled = true;
                  }else{
                    sorteo.disabled = false;
                  }
                  return sorteo;
                })
              }))
              defer.resolve([])
              return defer.promise;
            }else{
              return defer.promise;
            }
          }
        },
      ]
    }
  }).state('unsubscribe',{
    parent: 'webLayout',
    url: '/unsubscribe?id',
    component: 'unsubscribe',
  })
  .state('deportes',{
    parent: 'webLayout',
    url: '/deportes/:cashier_id?',
    component: 'deportes',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('caballos',{
    parent: 'webLayout',
    url: '/hipismo/:cashier_id?',
    component: 'caballos',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('rifamax',{
    parent: 'webLayout',
    url: '/rifamax/:cashier_id?',
    component: 'rifamax',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('resultados',{
    parent: 'agentspanel',
    url: '/resultados',
    component: 'resultados',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('noWinning',{
    parent: 'agentspanel',
    url: '/sorteo-tickets-no-ganadores/:cashier_id?',
    component: 'noWinning',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  });
}
