(function() {
  'use strict';
  angular
    .module('app')
    .factory('deportesService', deportesService);

  deportesService.$inject = ['$http', 'api'];

  function deportesService($http, api) {
    
    function launchSportsBook(data) {
      return $http.post(api.getApiSPJ('lobby/launch_sportbook'), data)
      .then(function(res){
        return res.data;
      })
    }

    

    return {
      launchSportsBook: launchSportsBook
    };

  }
})();
